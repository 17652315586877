import { getImagePath } from '@/helpers/utils'
import { defineStore } from 'pinia';

const getPackageInfo = (packageInfo = {}, destination = {}) => {
    return {
        id: packageInfo.id,
        name: packageInfo.name,
        slug: packageInfo.slug,
        image: packageInfo.image,
        includeInHome: packageInfo.includeInHome,
        soldOut: packageInfo.soldOut,
        nights: packageInfo.nights,
        adults: packageInfo.adults,
        children: packageInfo.children,
        plan: packageInfo.plan,
        childrenAgeRange: packageInfo.childrenAgeRange,
        retailPriceUSD: packageInfo.retailPriceUSD,
        priceUSD: packageInfo.priceUSD,
        additionalNightPrice: packageInfo.additionalNightPrice,
        savings: packageInfo.savings,
        discountPriceUSD: packageInfo.priceUSD,
        destinationName: destination.name,
        destinationSlug: destination.slug,
        destinationId: destination.id,
        isVip: packageInfo.vip.isVip,
        vipDiscount: packageInfo.vip.vipDiscount,
        status: packageInfo.status,
    }
}

export const useWebsiteStore = defineStore('website', {
    state() {
        return {
            team: localStorage.getItem('team'),
            source: localStorage.getItem('utm_source'),
            sourcePhone: localStorage.getItem('source_phone'),
            channel: localStorage.getItem('utm_medium'),
            leadID: localStorage.getItem('leadID'),
            campaignName: localStorage.getItem('utm_campaign'),
            now: new Date(),
            isCampaign: false,
            campaign: {
                fromDate: new Date(2024, 10, 14, 0, 0, 0),
                toDate: new Date(2024, 11, 2, 23, 59, 59),
                bgCampaign: {    
                    backgroundColor: '#c00657',
                  },
                bgBanner: {
                   backgrImage: require('@/assets/images/campaigns/black-friday-2024/banner.jpeg')
                },
                babyAsset: require('../assets/images/campaigns/black-friday-2024//baby-asset.png'),
                lbl01: 'Unbeatable Black Friday Beach Deals – Book Your 2025 Vacation Now!',
                lbl02: '',
                lbl03: '877 692 3883',
                btnColors: {   
                    background: 'linear-gradient(90deg, rgba(209,7,90,1) 0%, rgba(61,17,82,1) 100%)',
                    color: 'white',
                    border: '1px solid rgba(243,219,122,1)'
                },
                blocksColor: {
                    backgroundColor: '#390a50'
                },
                titleColor: {
                    color: '#ffff!important'
                },

                },
               /* eslint-disable */
               selectedVideo: 'https://media.tagembed.com/post/43792/153196/203210/17941806305814493.mp4?rand=17188158456666',
               reviewsCollection:
               {
                 "head": {
                   "status": true,
                   "code": 200
                 },
                 "body": {
                   "widgetlDetail": {
                     "widgetName": "Reviews VDAI API",
                     "widgetUrl": "636-1718298716",
                     "widgetProfanity": "1",
                     "widgetCreatedAt": "2024-06-13 22:41:56",
                     "count": 6
                   },
                   "feeds": [
                     {
                       "feedName": "Vacation Deals All Inclusive",
                       "networkName": "Facebook",
                       "feedId": "202498",
                       "postId": "5525032",
                       "postContent": "If you are in search of a vacation deal look no further. I really recommend contacting Jessica Canales who is very knowledgeable and courteous. She can be contacted at 8776060014 ext 520. She was amazing and thanks to her I recently visited Villa del Palmar in Cabo. Food was delicious and room was clean and very comfortable.",
                       "postScore": "pos",
                       "image": "",
                       "imageList": [],
                       "postLinkUrl": "https:\/\/facebook.com\/192304578163617",
                       "postPin": "0",
                       "postHighlight": "0",
                       "postAuthorName": "Alejandra Zamora-Escobedo",
                       "postUsername": "Alejandra Zamora-Escobedo",
                       "postUserPicture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXER8QZjYuNgfVEzl7t-PunaQUeB6RQocEwUvlbLfCpHuHZIfFawx3WVQM5WVwZ9XhOatu_Lva0X&psid=25275771298705087&height=50&width=50&ext=1724974526&hash=Abb0fe6EGJWI_BiqfQHzCh4j",
                       "postPicture": "https:\/\/platform-lookaside.fbsbx.com\/platform\/profilepic\/?eai=AXEqq-9okHVnTrW0keS3Tr04fZQOIub2eeOiQRHgWAhcgvIpjDDMiGYLEcefImTBw3xIKgeRPoCB&psid=25275771298705087&height=50&width=50&ext=1720890742&hash=AbYZP6z4MYt4By0R49eJxrNL",
                       "postCreatedAt": "1712348327",
                       "postMediaFile": "",
                       "rating": "5",
                       "postUpdatedAt": "1718298742",
                       "CTAtext": "",
                       "CTAbackground": "",
                       "CTAcolor": "",
                       "CTAurl": "javascript:void(0);"
                     },
                     {
                       "feedName": "Vacation Deals All Inclusive",
                       "networkName": "Facebook",
                       "feedId": "202498",
                       "postId": "5525033",
                       "postContent": "Adan my contact is very informed and handled all my needs very well. he is very accommodating.\nGave me good value for my money. Adan made my Cancun visit memorable!!\n\nI would recommend to speak with Adan before finalizing your vacation plans \n\nAdan Jesus Alvarez Vacation\n+52 322 157 8458",
                       "postScore": "pos",
                       "image": "",
                       "imageList": [],
                       "postLinkUrl": "https:\/\/facebook.com\/192304578163617",
                       "postPin": "0",
                       "postHighlight": "0",
                       "postAuthorName": "Anurag Khare",
                       "postUsername": "Anurag Khare",
                       "postUserPicture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGii9S_zsn_YC6N3q6Pxt3TM3Pdfemfbw-1LakqgOvMtjgOE_dc2_J95jaqwo8kaLrwbVd02PzW&psid=7322901294468198&height=50&width=50&ext=1724974526&hash=AbYB5Fs5QQfio3w5OT4IqpVE",
                       "postPicture": "https:\/\/platform-lookaside.fbsbx.com\/platform\/profilepic\/?eai=AXF-bka_zCUmHBGMPi_IuFVExpKDfugrnRzSkv9UHBLZeVbNkBoMgPgVku-pmwk6LWqyV1rBEVd4&psid=7322901294468198&height=50&width=50&ext=1720890742&hash=AbZUddJ5ynYzCBJXeW-mxKen",
                       "postCreatedAt": "1711399741",
                       "postMediaFile": "",
                       "rating": "5",
                       "postUpdatedAt": "1718298742",
                       "CTAtext": "",
                       "CTAbackground": "",
                       "CTAcolor": "",
                       "CTAurl": "javascript:void(0);"
                     },
                     {
                       "feedName": "Vacation Deals All Inclusive",
                       "networkName": "Facebook",
                       "feedId": "202498",
                       "postId": "5525034",
                       "postContent": "Last year in August we visited Villa Del Palmar All inclusive hotel in Cancun, and it was such a wonderful experience. We have recommended it to family and friends because our experience was unforgettable. The variety of restaurants that are within the hotel and there\u2019s no need to leave at all. The food was delicious!! Loved that the buffet would change the menu everyday. The drinks were also so convenient to just stop by at any bar and order a drink with the all inclusive package. Even while out at the private beach, the gentlemen come over and take out orders. Such a wonderful experience we had and we are already planning to go again. My girls loved it as well as my husband. Very impressed with this hotel. Thanks a lot to Jessica Canales, she was so easy to talk to and would respond quickly to us when we had questions. I highly recommend Jessica at 877-606-0014 Ext 520, at Vacation Deals All inclusive. She made our family vacation so easy to book. She\u2019s wonderful. Thanks!! \ud83d\ude0a",
                       "postScore": "pos",
                       "image": "",
                       "imageList": [],
                       "postLinkUrl": "https:\/\/facebook.com\/192304578163617",
                       "postPin": "0",
                       "postHighlight": "0",
                       "postAuthorName": "Maritza Yvette",
                       "postUsername": "Maritza Yvette",
                       "postUserPicture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXH9LvPAHpHdaIs1fmbREzRI6tz7C52q1JoerZRkuxc_OK1n3H-_4ERvnx_dEe88o2MsU2sG58It&psid=7747177741980499&height=50&width=50&ext=1724974526&hash=AbZKzSjj-vvMdaSMwAc7TENk",
                       "postPicture": "https:\/\/platform-lookaside.fbsbx.com\/platform\/profilepic\/?eai=AXE8bah6kwt-yE3wafeGbk9AWwZFaHKwa76ZMvyUENxemuuPIy3epuo9At692Sfh925o9_XzqHbY&psid=7747177741980499&height=50&width=50&ext=1720890742&hash=AbZtTtb3DRQ0g43f436w6knP",
                       "postCreatedAt": "1710195799",
                       "postMediaFile": "",
                       "rating": "5",
                       "postUpdatedAt": "1718298742",
                       "CTAtext": "",
                       "CTAbackground": "",
                       "CTAcolor": "",
                       "CTAurl": "javascript:void(0);"
                     },
                     {
                       "feedName": "Vacation Deals All Inclusive",
                       "networkName": "Facebook",
                       "feedId": "202498",
                       "postId": "5525035",
                       "postContent": "Recently had the time of our lives at Villa del Palmar Cancun with Vacation Deals All Inclusive! This family-friendly resort is beyond beautiful, offering stunning views of the ocean and delicious food from multiple restaurant options. Our suite was not only beautiful but impeccably clean. The staff were courteous and made us feel at home. Can't recommend this experience enough \u2013 it's a must for anyone seeking a memorable getaway! My daughter and I tried kayaking for the first time and they offer other fun activities at the resort too. My kids loved every minute of our stay and refused to leave. It is truly paradise. I can\u2019t wait to return! \n\nThank you Jessica Canales for being amazing and answering all of our questions. Definitely recommend contacting her to book the vacation of your lifetime. Her contact number is 8776060014 ext 520.",
                       "postScore": "pos",
                       "image": "",
                       "imageList": [],
                       "postLinkUrl": "https:\/\/facebook.com\/192304578163617",
                       "postPin": "0",
                       "postHighlight": "0",
                       "postAuthorName": "Claudia Garcia",
                       "postUsername": "Claudia Garcia",
                       "postUserPicture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGQPv4dW2KGC9pW0LOHKQmFCETEXDU1yFeSZmSNLIMRyGCtPtHew-ytBzSIKChf1q3WG1CmB_7q&psid=7303565599724126&height=50&width=50&ext=1724974526&hash=AbZ-_QIw0F4TTnxrch0BdyJ9",
                       "postPicture": "https:\/\/platform-lookaside.fbsbx.com\/platform\/profilepic\/?eai=AXGIE_wLFi2NSQJgMy1uPSFe3xE2c9weMf9XrmfWCjv4vjVuGClCsLLGofKsoLxmsTUpBVSGxwz5&psid=7303565599724126&height=50&width=50&ext=1720890742&hash=AbZqzb3-HvKXURbaWXjtEbWD",
                       "postCreatedAt": "1709864214",
                       "postMediaFile": "",
                       "rating": "5",
                       "postUpdatedAt": "1718298742",
                       "CTAtext": "",
                       "CTAbackground": "",
                       "CTAcolor": "",
                       "CTAurl": "javascript:void(0);"
                     },
                     {
                       "feedName": "Vacation Deals All Inclusive",
                       "networkName": "Facebook",
                       "feedId": "202498",
                       "postId": "5525036",
                       "postContent": "rating\nOur stay At Villa Del Palmar was Amazing! It\u2019s beautiful! The Drinks and food are delicious! The Hotel is very neat and Clean and all of the staff are super Friendly and attentive. I would definitely recommend Vacation Deals All Inclusive, so worth it! Our sales associate Jessica Canales was so helpful and answered all my questions and made sure we were taken care of. If you want to Visit Puerto Vallarta, Villa Del Palmar is the place to stay! You will enjoy every minute! If you want a great experience here is her contact information! Jessica Canales 8776060014 ext 520",
                       "postScore": "pos",
                       "image": "",
                       "imageList": [],
                       "postLinkUrl": "https:\/\/facebook.com\/192304578163617",
                       "postPin": "0",
                       "postHighlight": "0",
                       "postAuthorName": "Micah Cook",
                       "postUsername": "Micah Cook",
                       "postUserPicture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEephCE2_CvZ1l_XxlhOL1YcI-OdAgu1_5Vt3Im3sIBf9PAOp8cb3vGrzZuGWD6n9K8F9eywznd&psid=4233928146688098&height=50&width=50&ext=1724974526&hash=AbYlIFHxWIPOjqTy8EhQO7b",
                       "postPicture": "https:\/\/platform-lookaside.fbsbx.com\/platform\/profilepic\/?eai=AXEeUxe6OTUadruJbA_GkeNkVkd01WJefzWb4EobSlFTnuDrc2PSyoZ-rmsl0xBad_eTzanYeiYT&psid=4233928146688098&height=50&width=50&ext=1720890742&hash=Abb4nTtA5zrflUfQhFwmowOk",
                       "postCreatedAt": "1709851295",
                       "postMediaFile": "",
                       "rating": "5",
                       "postUpdatedAt": "1718298742",
                       "CTAtext": "",
                       "CTAbackground": "",
                       "CTAcolor": "",
                       "CTAurl": "javascript:void(0);"
                     },
                     {
                       "feedName": "Vacation Deals All Inclusive",
                       "networkName": "Facebook",
                       "feedId": "202498",
                       "postId": "5525037",
                       "postContent": "Excellent service Great prices . Look no further!",
                       "postScore": "pos",
                       "image": "",
                       "imageList": [],
                       "postLinkUrl": "https:\/\/facebook.com\/192304578163617",
                       "postPin": "0",
                       "postHighlight": "0",
                       "postAuthorName": "Ej Bello",
                       "postUsername": "Ej Bello",
                       "postUserPicture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFJfSNNVbAyRbBK4GmLkIYdFBIqcU0Oae_yMQTEYux1Ty609V5gdOTelI8tOMClwAwg770yEXEe&psid=4933842270055400&height=50&width=50&ext=1724974526&hash=Abah-sskPK4O643rN6vV1sox",
                       "postPicture": "https:\/\/platform-lookaside.fbsbx.com\/platform\/profilepic\/?eai=AXGSByrLb0H_M48VWP8Ef2-BC6Qfh42gA__LmoAZNDRZhQZOpgbVp0ikQIR6oPK08Nj1Y_LiO3tH&psid=4933842270055400&height=50&width=50&ext=1720890742&hash=AbboqpEXqv5ALW2zailVySST",
                       "postCreatedAt": "1709679831",
                       "postMediaFile": "",
                       "rating": "5",
                       "postUpdatedAt": "1718298742",
                       "CTAtext": "",
                       "CTAbackground": "",
                       "CTAcolor": "",
                       "CTAurl": "javascript:void(0);"
                     }
                   ]
                 }
               },
              reviewsVideoGallery: [
                {
                  id: 1,
                  posterUrl: 'https://media.tagembed.com/post/43792/153196/203210/17941806305814493.png?rand=17188158456666',
                  videoUrl: 'https://media.tagembed.com/post/43792/153196/203210/17941806305814493.mp4?rand=17188158456666'
                },
                {
                  id: 2,
                  posterUrl: 'https://media.tagembed.com/post/43792/153196/203210/18253139293223535.png?rand=17188158565275',
                  videoUrl: 'https://media.tagembed.com/post/43792/153196/203210/18253139293223535.mp4?rand=17188158565275'
                },
                {
                  id: 3,
                  posterUrl: 'https://media.tagembed.com/post/43792/153196/203210/18012430991134767.png?rand=17188158692313',
                  videoUrl: 'https://media.tagembed.com/post/43792/153196/203210/18012430991134767.mp4?rand=17188158692313'
                },
                {
                  id: 4,
                  posterUrl: 'https://media.tagembed.com/post/43792/153196/203210/18030286709047423.png?rand=17188158609535',
                  videoUrl: 'https://media.tagembed.com/post/43792/153196/203210/18030286709047423.mp4?rand=17188158609535'
                },
                {
                  id: 5,
                  posterUrl: 'https://media.tagembed.com/post/43792/153196/203210/17918886371912347.png?rand=17188158655119',
                  videoUrl: 'https://media.tagembed.com/post/43792/153196/203210/17918886371912347.mp4?rand=17188158655119'
                },
                {
                  id: 6,
             posterUrl: 'https://media.tagembed.com/post/43792/153196/203210/17941806305814493.png?rand=17188158456666',
                  videoUrl: 'https://media.tagembed.com/post/43792/153196/203210/17941806305814493.mp4?rand=17188158456666'
                }
              ],
            phones: [
                { terminal: "VTA:VDAI-OB-MAI", phone: "888 669 9803" },
                { terminal: "VTA:VDAI-IB-MAI", phone: "888 273 1373" },
                { terminal: "GDL:VDAI-OB-MAI", phone: "855 475 0031" },
                { terminal: "GDL:VDAI-IB-MAI", phone: "855 240 1519" },
                { terminal: "PVR:OB-NACIONALES", phone: "800 953 0839" },
                { terminal: "PVR:IB-NACIONALES", phone: "800 953 0848" },
                { terminal: "PVROB", phone: "855 723 4777" },
                { terminal: "PVRIB", phone: "855 981 0710" },
                { terminal: "GDLOB", phone: "877 695 2269" },
                { terminal: "GDLIB", phone: "877 695 2373" },
                { terminal: "TKTK", phone: "888 294 8431" },
                { terminal: "google", phone: "855 240 1527" },
            ],
            banners: [
                //considerar que la fecha debe comenzar en el mes 0.
                {
                    imageDesktop: require('@/assets/images/campaigns/veterans-day/banner.jpg'),
                    imageMobile: require('@/assets/images/campaigns/veterans-day/banner.jpg'),
                    fromDate: new Date(2024, 10, 8, 0, 0, 0),
                    toDate: new Date(2024, 10, 12, 23, 59, 59)
                },
                {
                    imageDesktop: require('@/assets/images/campaigns/veterans-day/banner.jpg'),
                    imageMobile: require('@/assets/images/campaigns/veterans-day/banner.jpg'),
                    fromDate: new Date(2024, 8, 20, 0, 0, 0),
                    toDate: new Date(2024, 8, 30, 23, 59, 59)
                },
            ],
            filterBanner: getImagePath("cover.webp"),
            faqs: [
                {
                    id: 'question1',
                    question: 'Why is this package so affordable?',
                    answer: 'We’re trying to bring tourism back to Mexico. '
                },
                {
                    id: 'q2',
                    question: 'How many rooms are at the hotel? ',
                    answer: 'Cancun 415 rooms / Cabo 460 rooms / Vallarta 395 rooms / Loreto 178 rooms.'
                },
                {
                    id: 'q3',
                    question: 'Does the resort allow pets?',
                    answer: 'No, only service dogs.'
                },
                {
                    id: 'q4',
                    question: 'Are handicapped rooms available?',
                    answer: 'Yes, upon request (subject to availability).'
                },
                {
                    id: 'q5',
                    question: 'Is this a time share?',
                    answer: 'It’s a 90-minute VIP tour: 30 minutes breakfast, 30 minutes tour walk around the resort and 30 minutes talk. '
                },
                {
                    id: 'q6',
                    question: 'How do I get directions to the resort?',
                    answer: 'Maps and directions to the resort will be provided in your email confirmation.'
                },
                {
                    id: 'q7',
                    question: 'What time is check-in and check-out? ',
                    answer: 'PV: Check-in time is at 3:00pm and check-out time is 12:00pm. CS: Check-in time is at 3:00pm and check-out time is 12:00pm. CUN: Check-in time is at 3:00pm and check-out time is 12:00pm. LOR: Check-in time is at 4:00pm and check-out time is 11:00am.'
                },
                {
                    id: 'q8',
                    question: 'Does it include flights?',
                    answer: 'We don’t include airfare, only resort accommodations.'
                },
                {
                    id: 'q9',
                    question: 'Does it have expiration? ',
                    answer: 'Yes, 18 months. '
                },
                {
                    id: 'q10',
                    question: 'How many restaurants are available with this promo? ',
                    answer: 'Six or more (depends of your destination).'
                },
            ],
            faqsEs: [
                {
                    id: 'question1',
                    question: '¿Por qué es tan barato este paquete?',
                    answer: 'Queremos reactivar el turismo en México.'
                },
                {
                    id: 'q2',
                    question: '¿Cuántas habitaciones hay en cada hotel?',
                    answer: 'Cancún 415 cuartos / Cabo 460 cuartos /Vallarta 395 cuartos / Loreto 178 cuartos.'
                },
                {
                    id: 'q3',
                    question: '¿El hotel acepta mascotas?',
                    answer: 'No, únicamente perros de servicio.'
                },
                {
                    id: 'q4',
                    question: '¿Hay habitaciones para personas con discapacidad?',
                    answer: 'Claro que sí, se pueden solicitar previamente (sujeto a disponibilidad).'
                },
                {
                    id: 'q5',
                    question: '¿Es un Tiempo Compartido?',
                    answer: 'Es una visita VIP de 90 minutos: 30 minutos de desayuno, 30 minutos de paseo por el hotel y 30 minutos de charla.'
                },
                {
                    id: 'q6',
                    question: '¿Cómo consigo la dirección de los hoteles?',
                    answer: 'Los mapas y las indicaciones para llegar al hotel se proporcionarán en su confirmación por correo electrónico.'
                },
                {
                    id: 'q7',
                    question: '¿Cuál es el horario de check-in y de salida?',
                    answer: 'PV: hora de entrada: 3:00pm y hora de salida: 12:00 pm. CS: hora de entrada: 3: 00 pm y hora de salida: 12:00 pm. CUN: hora de entrada: 3:00 pm y hora de salida: 12:00 pm. LOR: hora de entrada: 4:00 pm y hora de salida: 11:00 am.'
                },
                {
                    id: 'q8',
                    question: '¿El paquete incluye vuelos?',
                    answer: 'No incluimos boletos de avión, sólo el alojamiento.'
                },
                {
                    id: 'q9',
                    question: '¿El certificado tiene fecha de expiración?',
                    answer: 'Así es, tiene una durabilidad de 18 meses.'
                },
                {
                    id: 'q10',
                    question: ' ¿Cuántos restaurantes entran en la promoción?',
                    answer: '6 o más de acuerdo con el destino.'
                },
            ],
            destinations: [
                {
                    id: 1,
                    name: "Puerto Vallarta",
                    slug: "puerto-vallarta",
                    image: getImagePath("03.webp"),
                    video: {
                        poster: "https://media.tagembed.com/post/43792/69585/101567/18180361324223056.png",
                        url: "https://media.tagembed.com/post/43792/69585/101567/18180361324223056.mp4"
                    },
                    description:
                        "<p>Puerto Vallarta is a must destination on the Pacific Coast with a truly magical vibe with its cobblestone streets and colonial buildings. It's known for being a safe tropical location with great beaches and fun nightlife. Choose Puerto Vallarta if you want to experience a top vacation with a little town vibe.</p><p>Puerto Vallarta is a Mexican vacation experience unlike any other. The combined cultures of Spanish Mores, Catholicism and pre-Columbian mystical traditions creates an atmosphere of wonder and adventure. The beautiful architecture and cobblestone streets will make you feel like you’re experiencing authentic history, while also being surrounded by all of the other elements that make for an exotic Mexican vacation.</p><p>Majestic  Puerto Vallarta is surrounded on one side by the Sierra Madre Mountains, and beautiful Banderas Bay on the other.  Puerto Vallarta and the Bay area boast everything from breathtaking sunsets, warm sandy beaches, and incredible natural beauty to exciting mountain and ocean adventure.  Walk along the famous oceanfront Malecon (boardwalk), where restaurants, nightclubs, sightseeing and shopping offer something for every taste. Take a water taxi or whale watching tour to marine parks and villages that can only be reached by boat.  Swing through the jungle canopy, parasail over Los Muertos Beach, or stroll through Old Town and discover why Vallarta has been a vacation destination for more than 50 years.</p>",
                    packages: [
                        {
                            id: 1,
                            name: "Palmar Vallarta All Inclusive",
                            slug: "palmar-all-inclusive",
                            image: { url: require('@/assets/images/puerto-vallarta/package/palmar-all-inclusive/01-Resort/03.webp') },
                            includeInHome: true,
                            soldOut: false,
                            nights: 4,
                            adults: 2,
                            children: 2,
                            childrenAgeRange: 'Ask about including children',
                            plan: "All Inclusive",
                            retailPriceUSD: 1605,
                            priceUSD: 599,
                            additionalNightPrice: 199,
                            status: '',
                            vip: {
                                isVip: false,
                                vipDiscount: ""
                            },
                            offer: {
                                rating: 5,
                                includes: [
                                    "This amazing package includes 5 days and 4 nights for 2 adults.",
                                    "All Inclusive plan.",
                                ],
                                description:
                                    "<p>The resort is conveniently located close to Puerto Vallarta's highlights, a city surrounded by palm covered mountains, waterfalls, and rivers. The popular destination's famed ocean front promenade, the Malecon, with its souvenir shops and flea market, is a five-minute taxi ride or adventurous bus journey away from the hotel.</p> <p>Coupled with margarita sunsets, tequila tasting tours, swimming with the dolphins and much more, Puerto Vallarta is poised for adventure, relaxation and once in a lifetime experience. Let us provide the comfort you deserve on your all-inclusive vacation.<p>",
                                allInclusive: [
                                    "All Meals Daily, including Breakfast, Lunch, and Dinner",
                                    "Snacks Served All Day",
                                    "Weekly Theme Dinners",
                                    "Unlimited Beer, Wine, and Spirits",
                                    "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                                    "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                                    "Supervised Activities for Children Ages 4-12",
                                    "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                                    "Free yoga lessons are offered daily at 9am on the beach.",
                                ],
                            },
                            resort: {
                                gallery: [
                                    { url: require('@/assets/images/puerto-vallarta/package/palmar-all-inclusive/01-Resort/01.webp') },
                                    { url: require('@/assets/images/puerto-vallarta/package/palmar-all-inclusive/01-Resort/02.webp') },
                                    { url: require('@/assets/images/puerto-vallarta/package/palmar-all-inclusive/01-Resort/03.webp') },
                                    { url: require('@/assets/images/puerto-vallarta/package/palmar-all-inclusive/01-Resort/04.webp') },
                                    { url: require('@/assets/images/puerto-vallarta/package/palmar-all-inclusive/01-Resort/05.webp') }
                                ],
                                description:
                                    "<p>Located on the shores of Banderas Bay in the heart of the Hotel Zone is Villa del Palmar Beach Resort & Spa, an optional Puerto Vallarta all-inclusive resort that embodies the lively spirit of Mexico and the warm hospitality of only the best resorts in Puerto Vallarta. When you choose our resort, you’ll enjoy easy access to downtown, which is just a short distance away, onsite nightly entertainment, outdoor swimming pools, a slew of activities for guests of all ages, and top-notch service. </p>",
                            },
                            rooms:
                            {
                                gallery: [
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/palmar-all-inclusive/02-Suite/01.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/palmar-all-inclusive/02-Suite/02.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/palmar-all-inclusive/02-Suite/03.webp'),
                                        text: "",
                                    },
                                ],
                                name: "Deluxe Junior Suite",
                                priceUSD: 699,
                                additionalNightPrice: '',
                                description:
                                    "<p>With contemporary comforts, our Deluxe Junior Suite is the perfect suite for couples on vacation or small families. It features a spacious living area with two double beds, a spacious bathroom with a sunken tub, a dining table for four, and a sizable kitchen with everything you need for dining in or enjoying take-out from the resort’s restaurants. In addition, the private balcony allows moments for relaxation and enjoying the calming views.</p>",
                                galleryDescription: "Be our guest and see it for yourself.",
                                features: [
                                    {
                                        feature: "Air conditioning",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Climate control",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Flat screen television",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Premium television channels",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Living Room (from One Bedroom Suite and up)",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Dining table",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Telephone",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Fully equipped kitchen (Kitchenette in Junior Suite)",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Dishes, silver and kitchenware set",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Refrigerator",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Stove",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Microwave",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Coffee/tea maker",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Toaster",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Blender",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Can opener",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Safety deposit box",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Hair dryer",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Clock radio",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Iron &amp; ironing board",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Private, furnished balcony",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Daily housekeeping",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Electronic/magnetic keys",
                                        extraCharge: false
                                    }]

                            },
                            amenities: {
                                gallery: [
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/palmar-all-inclusive/03-Amenities/01.webp')
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/palmar-all-inclusive/03-Amenities/02.webp')
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/palmar-all-inclusive/03-Amenities/03.webp')
                                    },
                                ],
                                description: [
                                    {
                                        amenitie: "Gourmet restaurants and snack bar",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Palmita Market Deli",
                                        extraCharge: false
                                    }, {
                                        amenitie: "Room service",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "24-Hour front desk",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Bilingual staff",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Wireless internet ",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "Garden view and ocean view hotel suites",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Outdoor swimming pools and Jacuzzi",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Non-motorized water activities (Kayaks, Paddle Boards)",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Daytime beach and pool activities",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Nightly, live entertainment and theme nights",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Spa",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "Fitness Center with sauna and Jacuzzi",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Outdoor tennis court",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Kids Club ",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "Hospitality Suite (for early arrivals and late departures)",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Secure parking and valet",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Business services",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "Wedding services ",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "Dry cleaning and laundry service ",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "Medical Services ",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "Travel Agency",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "On-site car rental",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Daily housekeeping service",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Wheelchair accessibility",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Currency exchange",
                                        extraCharge: false
                                    },
                                ]
                            },
                            location: {
                                url: 'https://goo.gl/maps/uvd2E9V9A9QHU5az6',
                                map:
                                    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7467.134879623881!2d-105.2340295682312!3d20.646481038408105!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe9caf23b20368b60!2sVilla%20del%20Palmar%20Beach%20Resort%20%26%20Spa%2C%20Puerto%20Vallarta!5e0!3m2!1sen!2smx!4v1661879161825!5m2!1sen!2smx',
                            },
                        },
                        // {
                        //     id: 2,
                        //     name: "Las Palmas Vallarta All Inclusive",
                        //     slug: "las-Palmas-all-inclusive",
                        //     image: { url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/01-Resort/01.webp') },
                        //     includeInHome: false,
                        //     soldOut: false,
                        //     nights: 4,
                        //     adults: 2,
                        //     children: 2,
                        //     childrenAgeRange: '',
                        //     plan: "All Inclusive",
                        //     retailPriceUSD: 1200,
                        //     priceUSD: 699,
                        //     additionalNightPrice: 199,
                        //     status: '',
                        //     vip: {
                        //         isVip: true,
                        //         vipDiscount: 100
                        //     },
                        //     offer: {
                        //         rating: 5,
                        //         includes: [
                        //             "This amazing package includes 5 days and 4 nights for 2 adults.",
                        //             "All Inclusive plan.",
                        //         ],
                        //         description:
                        //             "<p>Las Palmas by the Sea is an All-Inclusive beach hotel that offers two swimming pools, an oceanfront hot tub and a variety of family friendly activities. This resort is conveniently located in the hotel zone of Puerto Vallarta and is also close to the downtown area and boardwalk.</p><p>The city of Puerto Vallarta is famous for its lovely surroundings, stunning views of the ocean and friendly people. Explore all the beautiful spots the city has to offer, such as, the historic center, the handicrafts market and the lovely colonial cathedral. Enjoy wonderful and conveniently located facilities, including a panoramic Jacuzzi, two swimming pools, sports bar and massage center. You will enjoy a pleasant and fun vacation in this lovely city, famous for its year-round good weather. Las Palmas by The Sea is the perfect place to spend a memorable vacation.</p>",
                        //         allInclusive: [
                        //             "All Meals Daily, including Breakfast, Lunch, and Dinner",
                        //             "Snacks Served All Day",
                        //             "Weekly Theme Dinners",
                        //             "Unlimited Beer, Wine, and Spirits",
                        //             "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                        //             "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                        //             "Supervised Activities for Children Ages 4-12",
                        //             "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                        //             "Free yoga lessons are offered daily at 9am on the beach.",
                        //         ],
                        //     },
                        //     resort: {
                        //         gallery: [
                        //             { url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/01-Resort/02.webp') },
                        //             { url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/01-Resort/03.webp') },
                        //             { url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/01-Resort/04.webp') },
                        //             { url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/01-Resort/05.webp') },
                        //             { url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/01-Resort/06.webp') }
                        //         ],
                        //         description:
                        //             "<p>Escape to Mexico’s spectacular Pacific coast at Las Palmas by the Sea, an all-inclusive resort just moments from Puerto Vallarta’s vibrant Malecon esplanade. Step into the lobby and immediately feel the warm, inviting ambiance of a family-friendly hotel with an authentic Mexican touch. Spend your days lounging and relaxing at our beachfront resort while you take in uninterrupted ocean views. Relax by one of two swimming pools framed by whispering palm trees or in our panoramic Jacuzzi overlooking the sea.</p>",
                        //     },
                        //     rooms:
                        //     {
                        //         gallery: [
                        //             {
                        //                 url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/02-Suite/01.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/02-Suite/02.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/02-Suite/03.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/02-Suite/04.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/02-Suite/05.webp'),
                        //                 text: "",
                        //             },
                        //         ],
                        //         name: "Standard Room",
                        //         priceUSD: 699,
                        //         additionalNightPrice: '',
                        //         description:
                        //             "<p>Our Standard Room is the perfect suite for couples or small families on vacation. With one King or two full beds, air conditioning, a bath with a shower and a remote-controlled color television, our cool, comfortable Garden or Mountain View Rooms offer the ideal retreat after a day of sun, sand and surf.</p>",
                        //         galleryDescription: "Be our guest and see it for yourself.",
                        //         features: [
                        //             {
                        //                 feature: "Hair dryer",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Bath with shower",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Telephone",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Air conditioning",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "TV With Cable and Remote Control",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "King Size Bed or Two Full Beds",
                        //                 extraCharge: false
                        //             },]

                        //     },
                        //     amenities: {
                        //         gallery: [
                        //             {
                        //                 url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/03-Amenities/01.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/03-Amenities/02.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/03-Amenities/03.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/03-Amenities/04.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/03-Amenities/05.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/03-Amenities/06.webp')
                        //             },
                        //         ],
                        //         description: [
                        //             {
                        //                 amenitie: "Wireless internet ",
                        //                 extraCharge: true
                        //             },
                        //             {
                        //                 amenitie: "Spa Services",
                        //                 extraCharge: true
                        //             },
                        //             {
                        //                 amenitie: "Adults Pool",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Family Pool",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Bilingual staff",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Guacamajazz Restaurant",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Fusión Restaurant",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Aires de Mar Restaurant",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Snack by the Sea Restaurant",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Kids Club",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Night Shows",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Scuba diving classes for beginners",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Aquatic Volleyball",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Activities program for kids",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Water aerobics",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Ping pong table",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Bingo",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Beach Volleyball",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Beach Soccer",
                        //                 extraCharge: false
                        //             },
                        //         ]
                        //     },
                        //     location: {
                        //         url: 'https://goo.gl/maps/BME2EaX3rnUZtcxv9',
                        //         map:
                        //             'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.821030136332!2d-105.23546678460156!3d20.636150506440007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x84214575fab7051b%3A0x56cf8c8d9edda9b3!2sLas%20Palmas%20by%20The%20Sea!5e0!3m2!1sen!2smx!4v1661880653828!5m2!1sen!2smx',
                        //     },
                        // },
                        {
                            id: 3,
                            name: "Grand Riviera Nayarit All Inclusive",
                            slug: "grand-riviera-all-inclusive",
                            image: { url: require('@/assets/images/puerto-vallarta/package/grand-riviera-nayarit/01-Resort/06.webp') },
                            includeInHome: false,
                            soldOut: false,
                            nights: 4,
                            adults: 2,
                            children: 2,
                            childrenAgeRange: 'Ask about including children',
                            plan: "All Inclusive",
                            retailPriceUSD: 1899,
                            priceUSD: 699,
                            additionalNightPrice: 255,
                            status: '',
                            vip: {
                                isVip: true,
                                vipDiscount: ""
                            },
                            offer: {
                                rating: 5,
                                includes: [
                                    "This amazing package includes 5 days and 4 nights for 2 adults.",
                                    "All Inclusive plan.",
                                ],
                                description:
                                    "<p>This upscale resort located on the endless beach of Playa Flamingos in the exclusive area of Riviera Nayarit. Enjoy the family friendly atmosphere while relaxing and unwinding. This Resort and Spa is close to Playa Bucerias, Flamingos Golf Club, Vallarta Adventures and Aquaventuras Park Vallarta. </p>",
                                allInclusive: [
                                    "All Meals Daily, including Breakfast, Lunch, and Dinner",
                                    "Snacks Served All Day",
                                    "Weekly Theme Dinners",
                                    "Unlimited Beer, Wine, and Spirits",
                                    "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                                    "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                                    "Supervised Activities for Children Ages 4-12",
                                    "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                                    "Free yoga lessons are offered daily at 9am on the beach.",
                                ],
                            },
                            resort: {
                                gallery: [
                                    { url: require('@/assets/images/puerto-vallarta/package/grand-riviera-nayarit/01-Resort/02.webp') },
                                    { url: require('@/assets/images/puerto-vallarta/package/grand-riviera-nayarit/01-Resort/03.webp') },
                                    { url: require('@/assets/images/puerto-vallarta/package/grand-riviera-nayarit/01-Resort/04.webp') },
                                    { url: require('@/assets/images/puerto-vallarta/package/grand-riviera-nayarit/01-Resort/05.webp') },
                                    { url: require('@/assets/images/puerto-vallarta/package/grand-riviera-nayarit/01-Resort/06.webp') }
                                ],
                                description:
                                    "<p>Escape to Mexico’s spectacular Pacific coast at Las Palmas by the Sea, an all-inclusive resort just moments from Puerto Vallarta’s vibrant Malecon esplanade. Step into the lobby and immediately feel the warm, inviting ambiance of a family-friendly hotel with an authentic Mexican touch. Spend your days lounging and relaxing at our beachfront resort while you take in uninterrupted ocean views. Relax by one of two swimming pools framed by whispering palm trees or in our panoramic Jacuzzi overlooking the sea.</p>",
                            },
                            rooms:
                            {
                                gallery: [
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-riviera-nayarit/02-Suite/01.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-riviera-nayarit/02-Suite/02.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-riviera-nayarit/02-Suite/03.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-riviera-nayarit/02-Suite/04.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-riviera-nayarit/02-Suite/05.webp'),
                                        text: "",
                                    },
                                ],
                                name: "Deluxe Resort View Suite",
                                priceUSD: 699,
                                additionalNightPrice: '',
                                description:
                                    "<p>Enjoy our Deluxe Resort View Suite with a large space of 301 ft2, distributed among its comfortable bedroom and living room, in addition to its practical and functional services such as air conditioning, cable TV and wireless internet.</p>",
                                galleryDescription: "Be our guest and see it for yourself.",
                                features: [
                                    {
                                        feature: "A queen size bed",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Air Conditioning",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Bathroom Amenities",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Cable/Satellite",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Coffee maker",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Available Cribs",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Hair dryer",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Iron",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Ironing board",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Voice Mail",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Minibar",
                                        extraCharge: true
                                    },
                                    {
                                        feature: "Facial Mirror",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Non-smoking rooms",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "LCD/Plasma display",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Security Box",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Shower head (Shower)",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Smoke Detectors",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Phone",
                                        extraCharge: true
                                    },
                                    {
                                        feature: "Wake-up Service",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Wireless Internet",
                                        extraCharge: false,
                                    }

                                ]
                            },
                            amenities: {
                                gallery: [
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/03-Amenities/01.webp')
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/03-Amenities/02.webp')
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/03-Amenities/03.webp')
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/03-Amenities/04.webp')
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/03-Amenities/05.webp')
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/las-palmas-vallarta-all-inclusive/03-Amenities/06.webp')
                                    },
                                ],
                                description: [
                                    {
                                        amenitie: "Three heated swimming pools",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "One pool for children at the Krystal Kamp (Kids Club)",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Beach Volleyball",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Aqua-aerobics",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Yoga in the Sky Bar",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Nearby Golf",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "Trivia games",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Spa Therapies",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Blackjack",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Dominoes tournament",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Sport Fishing",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "Sea turtle conservation program",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Daily theme nights at the Sky Bar, including DJs and karaoke",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Live evening entertainment by the pool",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Beach parties",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Film nights",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Fitness center",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Wedding services",
                                        extraCharge: true
                                    },
                                ]
                            },
                            location: {
                                url: 'https://www.google.com/maps/place/Krystal+Grand+Nuevo+Vallarta/@20.739621,-105.3198245,15z/data=!4m8!3m7!1s0x0:0xde7e8c531f8b6213!5m2!4m1!1i2!8m2!3d20.739621!4d-105.3198245',
                                map:
                                    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3731.2755933365784!2d-105.3220131850722!3d20.739620986155742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842140d17b76acd5%3A0xde7e8c531f8b6213!2sKrystal%20Grand%20Nuevo%20Vallarta!5e0!3m2!1ses-419!2smx!4v1661892557984!5m2!1ses-419!2smx',
                            },
                        },
                        {
                            id: 4,
                            name: "Grand Vallarta All Inclusive",
                            slug: "grand-vallarta-all-inclusive",
                            image: { url: require('@/assets/images/puerto-vallarta/package/grand-vallarta-all-inclusive/01-Resort/01.webp') },
                            includeInHome: true,
                            soldOut: false,
                            nights: 4,
                            adults: 2,
                            children: 2,
                            childrenAgeRange: 'Ask about including children',
                            plan: "All Inclusive",
                            retailPriceUSD: 1049,
                            priceUSD: 699,
                            additionalNightPrice: '',
                            status: '',
                            vip: {
                                isVip: true,
                                vipDiscount: ""
                            },
                            offer: {
                                rating: 5,
                                includes: [
                                    "This amazing package includes 5 days and 4 nights for 2 adults.",
                                    "All Inclusive plan.",
                                ],
                                description:
                                    "<p>Treat yourself to an unequaled all-inclusive resort experience at the best location in Puerto Vallarta. just steps away from the famous Malecon. Beach, sunshine, a tropical setting and vibrant spaces with color and joy. We offer everything you are looking for your vacation. It’s a breath-taking place to relax and enjoy a fun-filled getaway.</p>",
                                allInclusive: [
                                    "All Meals Daily, including Breakfast, Lunch, and Dinner",
                                    "Snacks Served All Day",
                                    "Weekly Theme Dinners",
                                    "Unlimited Beer, Wine, and Spirits",
                                    "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                                    "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                                    "Supervised Activities for Children Ages 4-12",
                                    "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                                    "Free yoga lessons are offered daily at 9am on the beach.",
                                ],
                            },
                            resort: {
                                gallery: [
                                    { url: require('@/assets/images/puerto-vallarta/package/grand-vallarta-all-inclusive/01-Resort/02.webp') },
                                    { url: require('@/assets/images/puerto-vallarta/package/grand-vallarta-all-inclusive/01-Resort/03.webp') },
                                    { url: require('@/assets/images/puerto-vallarta/package/grand-vallarta-all-inclusive/01-Resort/04.webp') },
                                    { url: require('@/assets/images/puerto-vallarta/package/grand-vallarta-all-inclusive/01-Resort/05.webp') },
                                    { url: require('@/assets/images/puerto-vallarta/package/grand-vallarta-all-inclusive/01-Resort/06.webp') }
                                ],
                                description:
                                    "<p>Buenaventura Grand Hotel & Great Moments Puerto Vallarta invites you to experience an all-inclusive program with its excellent cuisine and numerous activities suitable for the entire family. Our 231 rooms and suites include all of the comforts and amenities you’ll need for an unforgettable stay, regardless of the purpose of your trip.</p>",
                            },
                            rooms:
                            {
                                gallery: [
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-vallarta-all-inclusive/02-Suite/01.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-vallarta-all-inclusive/02-Suite/02.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-vallarta-all-inclusive/02-Suite/03.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-vallarta-all-inclusive/02-Suite/04.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-vallarta-all-inclusive/02-Suite/05.webp'),
                                        text: "",
                                    },
                                ],
                                name: "Resort Room Suite",
                                priceUSD: 699,
                                additionalNightPrice: '',
                                description:
                                    "<p>Enjoy our Resort Room Suite, a comfortable and spacious room with a fresh contemporary Mexican style. With access to a private balcony if available. Enjoy with your family of its amenities while you enjoy your vacation in Puerto Vallarta.</p>",
                                galleryDescription: "Be our guest and see it for yourself.",
                                features: [
                                    {
                                        feature: "Daily Maid and Wake-up Service",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Telephone",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Hair Dryer",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Iron and Ironing Board",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Mini-bar With Soda, Beer and Water (complimentary daily refill)",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Coffee Maker",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "24-Hour Room Service",
                                        extraCharge: true
                                    },
                                    {
                                        feature: "In-room Safety Deposit Box",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Free Wi-Fi",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Flat-screen Cable TV (more than 60 channels)",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Individually Controlled Air Conditioning",
                                        extraCharge: false
                                    }
                                ]

                            },
                            amenities: {
                                gallery: [
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-vallarta-all-inclusive/03-Amenities/01.webp')
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-vallarta-all-inclusive/03-Amenities/02.webp')
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-vallarta-all-inclusive/03-Amenities/03.webp')
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-vallarta-all-inclusive/03-Amenities/04.webp')
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-vallarta-all-inclusive/03-Amenities/05.webp')
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-vallarta-all-inclusive/03-Amenities/06.webp')
                                    },
                                ],
                                description: [
                                    {
                                        amenitie: "Fully equipped Gym",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Free yoga lessons at the beach",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Candu Kids Club",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Spa Services",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Complimentary bikes",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Three heated pools",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Kids Pool",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Adults only area",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Boutique",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Hospitality Lounge",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Aqua Aerobics",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Beach Volleyball",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Pong Pong",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Beach Soccer",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Bingo",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Kayak Tours",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Water Polo",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Dance Classes",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Cooking classes",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Wireless internet",
                                        extraCharge: false
                                    }
                                ]
                            },
                            location: {
                                url: 'https://www.google.com/maps/place/Krystal+Grand+Nuevo+Vallarta/@20.739621,-105.3198245,15z/data=!4m8!3m7!1s0x0:0xde7e8c531f8b6213!5m2!4m1!1i2!8m2!3d20.739621!4d-105.3198245',
                                map:
                                    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3731.2755933365784!2d-105.3220131850722!3d20.739620986155742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842140d17b76acd5%3A0xde7e8c531f8b6213!2sKrystal%20Grand%20Nuevo%20Vallarta!5e0!3m2!1ses-419!2smx!4v1661892557984!5m2!1ses-419!2smx',
                            },
                        },
                        //{
                        //    id: 5,
                        //    name: "Mismaloya Vallarta All Inclusive",
                        //    slug: "mismaloya-vallarta-all-inclusive",
                        //    image: { url: require('@/assets/images/puerto-vallarta/package/mismaloya-vallarta/01-Resort/02.webp') },
                        //    includeInHome: true,
                        //    soldOut: false,
                        //    nights: 4,
                        //    adults: 2,
                        //    children: 2,
                        //childrenAgeRange: '',
                        //    plan: "All Inclusive",
                        //    retailPriceUSD: 1049,
                        //    priceUSD: 699,
                        //    additionalNightPrice: '',
                        //    status: '',
                        //    vip: {
                        //        isVip: true,
                        //        vipDiscount: ""
                        //    },
                        //    offer: {
                        //        rating: 5,
                        //        includes: [
                        //            "This amazing package includes 5 days and 4 nights for 2 adults.",
                        //            "All Inclusive plan.",
                        //        ],
                        //        description:
                        //            "<p>Barceló Puerto Vallarta is located on the shores of the beautiful Mismaloya Beach in the south of Puerto Vallarta. It is surrounded by lush mountains and boasts incredible views of the Pacific Ocean. Enjoy our Mexican-style hotel which is distinguished by its excellent service, categorized as a 5 star and 4 diamond hotel by the AAA.</p>",
                        //        allInclusive: [
                        //            "All Meals Daily, including Breakfast, Lunch, and Dinner",
                        //            "Snacks Served All Day",
                        //            "Weekly Theme Dinners",
                        //            "Unlimited Beer, Wine, and Spirits",
                        //            "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                        //            "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                        //            "Supervised Activities for Children Ages 4-12",
                        //            "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                        //            "Free yoga lessons are offered daily at 9am on the beach.",
                        //        ],
                        //    },
                        //    resort: {
                        //        gallery: [
                        //            { url: require('@/assets/images/puerto-vallarta/package/mismaloya-vallarta/01-Resort/02.webp') },
                        //            { url: require('@/assets/images/puerto-vallarta/package/mismaloya-vallarta/01-Resort/03.webp') },
                        //            { url: require('@/assets/images/puerto-vallarta/package/mismaloya-vallarta/01-Resort/04.webp') },
                        //            { url: require('@/assets/images/puerto-vallarta/package/mismaloya-vallarta/01-Resort/05.webp') },
                        //            { url: require('@/assets/images/puerto-vallarta/package/mismaloya-vallarta/01-Resort/06.webp') }
                        //        ],
                        //        description:
                        //            "<p>The hotel offers 316 spacious and luxury suites that will exceed your expectations of beauty and comfort. All of them have balconies, from where guests can admire the lush gardens, crystal clear pools and the breathtaking Banderas Bay. </p><p>The hotel boasts a wide range of cuisine, which includes traditional Mexican dishes, as well as other exquisite Spanish, Italian, and Japanese specialty dishes, along with a variety of exotic drinks which can be enjoyed by any of the 4 swimming pools or on the semi-private beach. All this and more included in the Barceló All Inclusive Program.</p>"
                        //    },
                        //    rooms:
                        //    {
                        //        gallery: [
                        //            {
                        //                url: require('@/assets/images/puerto-vallarta/package/mismaloya-vallarta/02-Suite/01.webp'),
                        //                text: "",
                        //            },
                        //            {
                        //                url: require('@/assets/images/puerto-vallarta/package/mismaloya-vallarta/02-Suite/02.webp'),
                        //                text: "",
                        //            },
                        //            {
                        //                url: require('@/assets/images/puerto-vallarta/package/mismaloya-vallarta/02-Suite/03.webp'),
                        //                text: "",
                        //            },
                        //            {
                        //                url: require('@/assets/images/puerto-vallarta/package/mismaloya-vallarta/02-Suite/04.webp'),
                        //                text: "",
                        //            },
                        //            {
                        //                url: require('@/assets/images/puerto-vallarta/package/mismaloya-vallarta/02-Suite/05.webp'),
                        //                text: "",
                        //            },
                        //        ],
                        //        name: "Resort Room Suite",
                        //        priceUSD: 699,
                        //        additionalNightPrice: '',
                        //        description:
                        //            "<p>The Deluxe Suites are perfect for a luxury vacation. Its 68 m² will make your stay an ideal place to enjoy maximum comfort. They offer a classic and elegant decor with touches of color to provide a cozy atmosphere   in which to relax both as a family and as a couple.</p>",
                        //        galleryDescription: "Be our guest and see it for yourself.",
                        //        features: [
                        //            {
                        //                feature: "Wi-Fi connection.",
                        //                extraCharge: false
                        //            },
                        //            {
                        //                feature: "Safe.",
                        //                extraCharge: false
                        //            },
                        //            {
                        //                feature: "Desk.",
                        //                extraCharge: false
                        //            },
                        //            {
                        //                feature: "Minibar.",
                        //                extraCharge: false
                        //            },
                        //            {
                        //                feature: "King bed.",
                        //                extraCharge: false
                        //            },
                        //            {
                        //                feature: "Shower.",
                        //                extraCharge: false
                        //            },
                        //            {
                        //                feature: "32'' TV.",
                        //                extraCharge: false
                        //            },
                        //            {
                        //                feature: "Air conditioning.",
                        //                extraCharge: false
                        //            },
                        //            {
                        //                feature: "Iron and ironing board.",
                        //                extraCharge: false
                        //            },
                        //            {
                        //                feature: "Hair dryer",
                        //                extraCharge: false
                        //            },
                        //            {
                        //                feature: "Bathrobe and slippers",
                        //                extraCharge: false
                        //            }
                        //        ]

                        //    },
                        //    amenities: {
                        //        gallery: [
                        //            {
                        //                url: require('@/assets/images/puerto-vallarta/package/mismaloya-vallarta/03-Amenities/01.webp')
                        //            },
                        //            {
                        //                url: require('@/assets/images/puerto-vallarta/package/mismaloya-vallarta/03-Amenities/02.webp')
                        //            },
                        //            {
                        //                url: require('@/assets/images/puerto-vallarta/package/mismaloya-vallarta/03-Amenities/03.webp')
                        //            },
                        //            {
                        //                url: require('@/assets/images/puerto-vallarta/package/mismaloya-vallarta/03-Amenities/04.webp')
                        //            },
                        //            {
                        //                url: require('@/assets/images/puerto-vallarta/package/mismaloya-vallarta/03-Amenities/05.webp')
                        //            },
                        //            {
                        //                url: require('@/assets/images/puerto-vallarta/package/mismaloya-vallarta/03-Amenities/06.webp')
                        //            },
                        //        ],
                        //        description: [
                        //            {
                        //                amenitie: "Restaurant la fuente",
                        //                extraCharge: false
                        //            },
                        //            {
                        //                amenitie: "Los Arcos Restaurant",
                        //                extraCharge: false
                        //            },
                        //            {
                        //                amenitie: "Restaurant Don Quijote",
                        //                extraCharge: false
                        //            },
                        //            {
                        //                amenitie: "Beauty salon",
                        //                extraCharge: false
                        //            },
                        //            {
                        //                amenitie: "U-Spa",
                        //                extraCharge: false
                        //            },
                        //            {
                        //                amenitie: "Pool Bar",
                        //                extraCharge: false
                        //            },
                        //            {
                        //                amenitie: "Los Arcos Snack Bar",
                        //                extraCharge: false
                        //            },
                        //            {
                        //                amenitie: "La Cantina Bar",
                        //                extraCharge: false
                        //            },
                        //            {
                        //                amenitie: "Barcy Club",
                        //                extraCharge: false
                        //            },
                        //        ]
                        //    },
                        //    location: {
                        //        url: 'https://goo.gl/maps/jh1BAY4JSx3upc3t8',
                        //        map:
                        //            'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d934.0909797626279!2d-105.29191256404499!3d20.53228577506853!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842145454a68ce77%3A0xee620ce3b7437166!2sBarcel%C3%B3%20Puerto%20Vallarta!5e0!3m2!1ses!2smx!4v1674605776081!5m2!1ses!2smx',
                        //    },
                        //},
                        {
                            id: 6,
                            name: "Grand Nuevo Vallarta All Inclusive",
                            slug: "grand-nuevo-vallarta-all-inclusive",
                            image: { url: require('@/assets/images/puerto-vallarta/package/grand-nuevo/01-Resort/02.webp') },
                            includeInHome: true,
                            soldOut: false,
                            nights: 4,
                            adults: 2,
                            children: 2,
                            childrenAgeRange: 'Ask about including children',
                            plan: "All Inclusive",
                            retailPriceUSD: 1049,
                            priceUSD: 699,
                            additionalNightPrice: '',
                            status: '',
                            vip: {
                                isVip: true,
                                vipDiscount: ""
                            },
                            offer: {
                                rating: 5,
                                includes: [
                                    "This amazing package includes 5 days and 4 nights for 2 adults.",
                                    "All Inclusive plan.",
                                ],
                                description:
                                    "<p>The Occidental Nuevo Vallarta hotel is an all-inclusive resort located in a privileged enclave in the middle of the incredible landscape of the Riviera Nayarit, where in the same morning it is possible to enjoy a paradisiacal beach and the best shopping and gastronomic area. Located only 25 minutes from Puerto Vallarta's international airport, this resort is easily accessible.</p>",
                                allInclusive: [
                                    "All Meals Daily, including Breakfast, Lunch, and Dinner",
                                    "Snacks Served All Day",
                                    "Weekly Theme Dinners",
                                    "Unlimited Beer, Wine, and Spirits",
                                    "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                                    "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                                    "Supervised Activities for Children Ages 4-12",
                                    "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                                    "Free yoga lessons are offered daily at 9am on the beach.",
                                ],
                            },
                            resort: {
                                gallery: [
                                    { url: require('@/assets/images/puerto-vallarta/package/grand-nuevo/01-Resort/02.webp') },
                                    { url: require('@/assets/images/puerto-vallarta/package/grand-nuevo/01-Resort/03.webp') },
                                    { url: require('@/assets/images/puerto-vallarta/package/grand-nuevo/01-Resort/04.webp') },
                                    { url: require('@/assets/images/puerto-vallarta/package/grand-nuevo/01-Resort/05.webp') },
                                    { url: require('@/assets/images/puerto-vallarta/package/grand-nuevo/01-Resort/06.webp') }
                                ],
                                description:
                                    "<p>The rooms, spread over 7 floors, have been sophisticatedly designed and equipped to offer guests an unbeatable stay and include outstanding services such as room service, 24-hour reception and free on-site parking.</p><p>It offers a variety of activities and excursions for all ages and tastes, including water sports such as kayaking. The Occidental Nuevo Vallarta has an especially attentive staff to ensure a pleasant stay while enjoying the privilege of an idyllic setting where the beach is the main protagonist.</p>"
                            },
                            rooms:
                            {
                                gallery: [
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-nuevo/02-Suite/01.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-nuevo/02-Suite/02.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-nuevo/02-Suite/03.webp'),
                                        text: "",
                                    },
                                ],
                                name: "Resort Room Suite",
                                priceUSD: 699,
                                additionalNightPrice: '',
                                description:
                                    "<p>The Deluxe Rooms, offering 312 square feet of space, feature garden or pool views and come fully equipped with 2 double beds or a King-size bed. These modern spaces offer contemporary decoration, with modern furniture and warm details to provide a welcoming atmosphere ideal for relaxing.</p>",
                                galleryDescription: "Be our guest and see it for yourself.",
                                features: [
                                    {
                                        feature: "Wi-Fi connection.",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Safe.",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Minibar replenished daily",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Cable television",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Shower.",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "TV.",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Air conditioning.",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Iron and ironing board.",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Hair dryer",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Coffee/Tea maker",
                                        extraCharge: false
                                    }
                                ]

                            },
                            amenities: {
                                gallery: [
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-nuevo/03-Amenities/01.webp')
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-nuevo/03-Amenities/02.webp')
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-nuevo/03-Amenities/03.webp')
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-nuevo/03-Amenities/04.webp')
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-nuevo/03-Amenities/05.webp')
                                    },
                                    {
                                        url: require('@/assets/images/puerto-vallarta/package/grand-nuevo/03-Amenities/06.webp')
                                    },
                                ],
                                description: [
                                    {
                                        amenitie: "Restaurant la fuente",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Room Service",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie: "24-hour front desk",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie: "Fitness Center",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie: "Restaurant",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie: "Airport Transportation",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie: "Business Center",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie: "Shuffleboard",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie: "Complimentary Wi-Fi in the Royal Level Lounge (Royal Level guests only)",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie: "Wi-Fi available for an additional charge in the rooms ($)",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie: "Car Rental",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie: "Valet parking service",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie: "Room service ($)",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie: "24-hour front desk",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie: "Laundry/dry cleaning/ironing service ($)",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie: "Currency exchange",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie: "Luggage storage",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie: "Concierge service",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie: "Tourist information",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie: "Ticket sales",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie: "Printing of electronic tickets",
                                        extraCharge: "false"
                                    },
                                    {
                                        amenitie:
                                            "Fax/photocopier ($)"
                                    }
                                ]
                            },
                            location: {
                                url: 'https://goo.gl/maps/xwnRZnymdJZa19eV9',
                                map:
                                    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3731.2755933365784!2d-105.3220131850722!3d20.739620986155742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842140d17b76acd5%3A0xde7e8c531f8b6213!2sKrystal%20Grand%20Nuevo%20Vallarta!5e0!3m2!1ses-419!2smx!4v1661892557984!5m2!1ses-419!2smx',
                            },
                        },
                    ],
                    physical: {
                        lon: -105.220306,
                        lat: 20.6407176,
                    }
                },
                {
                    id: 2,
                    name: "Cabo San Lucas",
                    slug: "cabo-san-lucas",
                    image: require('@/assets/images/cabo-san-lucas/destination/03.webp'),
                    video: {
                        poster: "https://media.tagembed.com/post/43792/69585/101567/18326937349006298.png",
                        url: "https://media.tagembed.com/post/43792/69585/101567/18326937349006298.mp4"
                    },
                    description:
                        "<p>Los Cabos (the capes) is a large municipality spanning the East and West Capes at the tip of the state of Baja California Sur. Los Cabos is located 900 miles southeast of San Diego, California. The Tropic of Cancer crosses the peninsula 50 miles north of Land's End. The two main tourist areas of Los Cabos are Cabo San Lucas and San José del Cabo. In the 1950s, Hollywood celebrities flew down to Cabo in private planes to fish. The two-lane paved highway from Tijuana opened in 1974, and tourists began discovering the secret of Los Cabos.</p><p>This is one of the very few 'Tropical Deserts' in the world - only 7 inches of rain a year, and no rainy season, virtually a sun-guarantee, with relatively low humidity compared with other tropical areas due to the absence of rain, and the influence of cooler Pacific breezes. This and close proximity, more than anything else, has been the reason for Cabo's popularity.</p>",
                    packages: [
                        {
                            id: 5,
                            name: "Palmar Cabo All Inclusive",
                            slug: "palmar-cabo-all-inclusive",
                            image: { url: require('@/assets/images/cabo-san-lucas/packages/01-palmar-cabo-all-inclusive/01-Resort/01.webp') },
                            includeInHome: false,
                            soldOut: false,
                            nights: 4,
                            adults: 2,
                            children: 2,
                            childrenAgeRange: 'Ask about including children',
                            plan: "All Inclusive",
                            retailPriceUSD: 1506,
                            priceUSD: 599,
                            additionalNightPrice: '',
                            status: '',
                            vip: {
                                isVip: false,
                                vipDiscount: 0,
                            },
                            offer: {
                                rating: 5,
                                includes: [
                                    "This amazing package includes 5 days and 4 nights for 2 adults.",
                                    "All Inclusive plan.",
                                ],
                                description:
                                    "<p>Just a few minutes’ walk from downtown Cabo San Lucas, this 5-star all-inclusive resort is one of the privileged hotel complexes in Cabo to be located on the beach. Its large swimming pools with waterfalls and a fun whale slide make it a perfect place for a family vacation, while its location is ideal for couples who wish to take advantage of what downtown Cabo has to offer.</p><p>Whether on water or on land, Cabo San Lucas has a number of fun and adventurous activities for all ages: sunset cruises, camelback desert tours, golfing, whale and sea lion watching, zip lining, to name but a few. Take advantage of this all-inclusive package today and secure wonderful memories for the future.</p> ",
                                allInclusive: [
                                    "All Meals Daily, including Breakfast, Lunch, and Dinner",
                                    "Snacks Served All Day",
                                    "Weekly Theme Dinners",
                                    "Unlimited Beer, Wine, and Spirits",
                                    "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                                    "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                                    "Supervised Activities for Children Ages 4-12",
                                    "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                                    "Free yoga lessons are offered daily at 9am on the beach.",
                                ],
                            },
                            resort: {
                                gallery: [
                                    { url: require('@/assets/images/cabo-san-lucas/packages/01-palmar-cabo-all-inclusive/01-Resort/02.webp') },
                                    { url: require('@/assets/images/cabo-san-lucas/packages/01-palmar-cabo-all-inclusive/01-Resort/03.webp') },
                                    { url: require('@/assets/images/cabo-san-lucas/packages/01-palmar-cabo-all-inclusive/01-Resort/04.webp') },
                                    { url: require('@/assets/images/cabo-san-lucas/packages/01-palmar-cabo-all-inclusive/01-Resort/05.webp') },
                                    { url: require('@/assets/images/cabo-san-lucas/packages/01-palmar-cabo-all-inclusive/01-Resort/06.webp') }
                                ],
                                description:
                                    "<p>Villa del Palmar Beach Resort & Spa is an optional Cabo San Lucas all-inclusive resort that is perfect for family getaways and romantic couple retreats. Located on Medano Beach in Cabo Mexico, just one mile from downtown, our exquisite resort offers the ultimate in lively entertainment, family-friendly activities, outdoor swimming pools, beachfront Jacuzzis, and world-class service.</p>",
                            },
                            rooms:
                            {
                                gallery: [
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/01-palmar-cabo-all-inclusive/02-Suite/01.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/01-palmar-cabo-all-inclusive/02-Suite/02.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/01-palmar-cabo-all-inclusive/02-Suite/03.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/01-palmar-cabo-all-inclusive/02-Suite/04.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/01-palmar-cabo-all-inclusive/02-Suite/05.webp'),
                                        text: "",
                                    },
                                ],
                                name: "Junior Suite",
                                priceUSD: 699,
                                additionalNightPrice: '',
                                description:
                                    "<p>The Junior Suite at Villa del Palmar Cabo San offers all the room you need to kick back and relax. There are two double beds or one queen-size bed and one bathroom with Travertine marble accents along with a kitchenette, breakfast bar, a sofa and balcony or terrace with furniture. At this Los Cabos all-inclusive resort, you’ll find the comfort, style, and privacy you need in our luxury suites. </p>",
                                galleryDescription: "Be our guest and see it for yourself.",
                                features: [
                                    {
                                        feature: "Air conditioning",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Climate control",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Flat screen television",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Premium television channels",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Phone",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Kitchenette",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "China/glassware/utensils",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Mini bar",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Coffee/tea maker",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Toaster",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Safe deposit box",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Iron & ironing board",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Private Balcony or terrace with ocean or garden view",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Daily housekeeping",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Electronic/magnetic keys",
                                        extraCharge: false
                                    },]

                            },
                            amenities: {
                                gallery: [
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/01-palmar-cabo-all-inclusive/03-Amenities/01.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/01-palmar-cabo-all-inclusive/03-Amenities/02.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/01-palmar-cabo-all-inclusive/03-Amenities/03.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/01-palmar-cabo-all-inclusive/03-Amenities/04.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/01-palmar-cabo-all-inclusive/03-Amenities/05.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/01-palmar-cabo-all-inclusive/03-Amenities/06.webp')
                                    },
                                ],
                                description: [
                                    {
                                        amenitie: "Gourmet restaurants and snack bar",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Palmita Market Deli",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "24-Hour room service",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "24-Hour front desk",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Bilingual staff",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Free WiFi",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Garden view and ocean view hotel suites",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Outdoor swimming pools and Jacuzzi",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Swimming pool for kids",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Non-motorized water activities (Kayaks, Paddle Boards, Snorkel, Boogies)",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Daytime beach and pool activities",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Nightly, live entertainment and theme nights",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Spa",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "Fitness Center and Hydrotherapy areas",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Outdoor tennis courts",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Kids Club",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Hospitality Suite (for early arrivals and late departures)",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Secure parking and valet",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Meeting/Banquet facilities",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Wedding services",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "Dry cleaning and laundry service",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "On-call medical assistance",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "Babysitting",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "Travel Agency",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "On-site car rental",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Daily housekeeping service",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Wheelchair accessibility",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "ATM",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Currency exchange",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Pre-arrival shopping",
                                        extraCharge: false
                                    }
                                ]
                            },
                            location: {
                                url: 'https://goo.gl/maps/6vyXfZe6BEa2xq1c6',
                                map:
                                    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14702.044912497819!2d-109.8966612!3d22.8945072!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xeda172f11147f5d2!2sVilla%20Del%20Palmar%20Beach%20Resort%20%26%20Spa%20Cabo%20San%20Lucas!5e0!3m2!1ses-419!2smx!4v1661900738179!5m2!1ses-419!2smx',
                            },
                        },
                        // {
                        //     id: 6,
                        //     name: "Real Cabo All Inclusive",
                        //     slug: "real-cabo-all-inclusive",
                        //     image: { url: require('@/assets/images/cabo-san-lucas/packages/02-real-cabo-all-inclusive/01-Resort/05.webp') },
                        //     includeInHome: false,
                        //     soldOut: false,
                        //     nights: 4,
                        //     adults: 2,
                        //     children: 2,
                        //     childrenAgeRange: '',
                        //     plan: "All Inclusive",
                        //     retailPriceUSD: 784,
                        //     priceUSD: 699,
                        //     additionalNightPrice: 229,
                        //     status: '',
                        //     vip: {
                        //         isVip: true,
                        //         vipDiscount: 100
                        //     },
                        //     offer: {
                        //         rating: 5,
                        //         includes: [
                        //             "This amazing package includes 5 days and 4 nights for 2 adults.",
                        //             "All Inclusive plan.",
                        //         ],
                        //         description:
                        //             "<p>Posada Real Los Cabos hotel enjoys a prime beachfront location, offering guests unlimited access to the pristine beaches of Los Cabos. It is conveniently located on the San Jose de Cabo hotel zone, very close to the new Puerto Los Cabos Marina and to some of the most renowned golf courses of the area including the Palmilla, Cabo Real, Club Campestre and Mayan Palace golf courses. The quaint, historic town of San Jose del Cabo, with its shops, restaurants and nightlife is just a mile from our lobby. Cabo San Lucas, with all of its attractions, is 20 minutes south. The Los Cabos International Airport is only fifteen minutes away.</p> ",
                        //         allInclusive: [
                        //             "All Meals Daily, including Breakfast, Lunch, and Dinner",
                        //             "Snacks Served All Day",
                        //             "Weekly Theme Dinners",
                        //             "Unlimited Beer, Wine, and Spirits",
                        //             "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                        //             "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                        //             "Supervised Activities for Children Ages 4-12",
                        //             "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                        //             "Free yoga lessons are offered daily at 9am on the beach.",
                        //         ],
                        //     },
                        //     resort: {
                        //         gallery: [
                        //             { url: require('@/assets/images/cabo-san-lucas/packages/02-real-cabo-all-inclusive/01-Resort/06.webp') },
                        //             { url: require('@/assets/images/cabo-san-lucas/packages/02-real-cabo-all-inclusive/01-Resort/03.webp') },
                        //             { url: require('@/assets/images/cabo-san-lucas/packages/02-real-cabo-all-inclusive/01-Resort/04.webp') },
                        //             { url: require('@/assets/images/cabo-san-lucas/packages/02-real-cabo-all-inclusive/01-Resort/05.webp') },
                        //             { url: require('@/assets/images/cabo-san-lucas/packages/02-real-cabo-all-inclusive/01-Resort/02.webp') }
                        //         ],
                        //         description:
                        //             "<p>Ideal to enjoy your dreamed beach vacation in Los Cabos, the Posada Real beach front hotel, is a haven of Mexican hospitality, located in the heart of the San Jose resort destination, at arm’s length of its main tourist attractions. A beautiful low-rise property, constructed in the Santa Fe style, has over 400 feet of private beachfront, all of the modern comforts, lovely cactus gardens typical of the region, and spectacular views of the deep blue colors of the Sea of Cortes.</p><p>Posada Real Los Cabos hotel, located on the beach, provides front-row access to this dynamic display of Mother Nature. A full-service, low-rise, beach resort hotel, Posada Real Los Cabos is a relaxed haven of Mexican hospitality and charm. Here, the exciting attractions of Los Cabos are minutes away, yet feel worlds apart. Experience the essence of Mexico at Posada Real Los Cabos.</p>",
                        //     },
                        //     rooms:
                        //     {
                        //         gallery: [
                        //             {
                        //                 url: require('@/assets/images/cabo-san-lucas/packages/02-real-cabo-all-inclusive/02-Suite/01.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/cabo-san-lucas/packages/02-real-cabo-all-inclusive/02-Suite/02.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/cabo-san-lucas/packages/02-real-cabo-all-inclusive/02-Suite/03.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/cabo-san-lucas/packages/02-real-cabo-all-inclusive/02-Suite/04.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/cabo-san-lucas/packages/02-real-cabo-all-inclusive/02-Suite/05.webp'),
                        //                 text: "",
                        //             },
                        //         ],
                        //         name: "Standard Room with Garden View",
                        //         priceUSD: 699,
                        //         additionalNightPrice: '',
                        //         description:
                        //             "<p>Comfortable, spacious room with a marble-lined bathroom and shower with tub. In this category, you can enjoy fantastic views of the hotel's semi-desert gardens. For your comfort, our rooms have 1 King size bed or two double beds.</p>",
                        //         galleryDescription: "Be our guest and see it for yourself.",
                        //         features: [
                        //             {
                        //                 feature: "Large terrace",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Air conditioning",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "HD screen with cable signal",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Telephone",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Coffee maker",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Desk",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Hairdryer",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Safe",
                        //                 extraCharge: false
                        //             }
                        //             ,]

                        //     },
                        //     amenities: {
                        //         gallery: [
                        //             {
                        //                 url: require('@/assets/images/cabo-san-lucas/packages/02-real-cabo-all-inclusive/03-Amenities/01.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/cabo-san-lucas/packages/02-real-cabo-all-inclusive/03-Amenities/02.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/cabo-san-lucas/packages/02-real-cabo-all-inclusive/03-Amenities/03.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/cabo-san-lucas/packages/02-real-cabo-all-inclusive/03-Amenities/04.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/cabo-san-lucas/packages/02-real-cabo-all-inclusive/03-Amenities/05.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/cabo-san-lucas/packages/02-real-cabo-all-inclusive/03-Amenities/06.webp')
                        //             },
                        //         ],
                        //         description: [
                        //             {
                        //                 amenitie: "148 standard roms",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "4 suites",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "2 restaurants",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "2 bars",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "1 heated pool",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "1 heated wading pool",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "1 jacuzzi",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Lovely cactus gardens",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Hammocks",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Wireless internet",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Business center",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Beach volleyball court",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Putting green",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Parking lot",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Travel and tour agency",
                        //                 extraCharge: true
                        //             },
                        //             {
                        //                 amenitie: "Tobacco shop",
                        //                 extraCharge: true
                        //             },
                        //             {
                        //                 amenitie: "Laundry service",
                        //                 extraCharge: true
                        //             },
                        //             {
                        //                 amenitie: "Room service",
                        //                 extraCharge: true
                        //             },
                        //             {
                        //                 amenitie: "Massage services by the beach",
                        //                 extraCharge: true
                        //             },
                        //             {
                        //                 amenitie: "Taxi Service",
                        //                 extraCharge: true
                        //             },
                        //             {
                        //                 amenitie: "Babysitting service",
                        //                 extraCharge: true
                        //             },
                        //             {
                        //                 amenitie: "24-hour medical service upon request",
                        //                 extraCharge: true
                        //             },

                        //         ]
                        //     },
                        //     location: {
                        //         url: 'https://goo.gl/maps/ZFamhJdRFni7C3D5A',
                        //         map:
                        //             'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.4772333017704!2d-109.70095998503184!3d23.042959084942737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86af50850f57526d%3A0x50f9121b778f9b76!2sPosada%20Real%20Los%20Cabos!5e0!3m2!1ses-419!2smx!4v1661902035046!5m2!1ses-419!2smx'
                        //     },
                        // },
                        {
                            id: 7,
                            name: "Grand San Jose All Inclusive",
                            slug: "grand-san-jose-all-inclusive",
                            image: { url: require('@/assets/images/cabo-san-lucas/packages/03-grand-san-jose-all-inclusive/01-Resort/06.webp') },
                            includeInHome: false,
                            soldOut: false,
                            nights: 4,
                            adults: 2,
                            children: 2,
                            childrenAgeRange: 'Ask about including children',
                            plan: "All Inclusive",
                            retailPriceUSD: 1605,
                            priceUSD: 699,
                            additionalNightPrice: '',
                            status: '',
                            vip: {
                                isVip: true,
                                vipDiscount: 100
                            },
                            offer: {
                                rating: 5,
                                includes: [
                                    "This amazing package includes 5 days and 4 nights for 2 adults.",
                                    "All Inclusive plan.",
                                ],
                                description:
                                    "<p>Are you getting bored with the same four walls at home? Pack your bags for a breath of fresh air at this Resort & Spa in Los Cabos. Contemporary design meets quiet sophistication right on the beach, and the resort's unique location between the desert and the Sea of Cortés sets the mood for a special getaway. Nestled in the planned resort community of La Jolla, the peaceful setting is just 30 minutes from the exciting nightlife in downtown Cabo.</p> ",
                                allInclusive: [
                                    "All Meals Daily, including Breakfast, Lunch, and Dinner",
                                    "Snacks Served All Day",
                                    "Weekly Theme Dinners",
                                    "Unlimited Beer, Wine, and Spirits",
                                    "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                                    "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                                    "Supervised Activities for Children Ages 4-12",
                                    "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                                    "Free yoga lessons are offered daily at 9am on the beach.",
                                ],
                            },
                            resort: {
                                gallery: [
                                    { url: require('@/assets/images/cabo-san-lucas/packages/03-grand-san-jose-all-inclusive/01-Resort/06.webp') },
                                    { url: require('@/assets/images/cabo-san-lucas/packages/03-grand-san-jose-all-inclusive/01-Resort/03.webp') },
                                    { url: require('@/assets/images/cabo-san-lucas/packages/03-grand-san-jose-all-inclusive/01-Resort/04.webp') },
                                    { url: require('@/assets/images/cabo-san-lucas/packages/03-grand-san-jose-all-inclusive/01-Resort/05.webp') },
                                    { url: require('@/assets/images/cabo-san-lucas/packages/03-grand-san-jose-all-inclusive/01-Resort/02.webp') }
                                ],
                                description:
                                    "<p>This resort faces 175 yards of warm, sun-kissed sand on a public beach for long hours catching the rays with a margarita in hand. Tired of pesky tan lines? The beach is clothing-optional. Not your style? Take a dip in the huge infinity-edge pool, or swim up to the bar for a shot of tequila before deciding on dinner. Three newly-renovated resort dining options hit the spot: Italian and Mexican fusion comes to life at Dolce Mexico, and the lemon drop martinis go down great with dragon rolls at the Sushi and Martini Bar. Want to catch a game after the sun goes down? Pop the cap on a cerveza and keep score with the two flat-screen TVs at Bar Nautico.</p>",
                            },
                            rooms:
                            {
                                gallery: [
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/03-grand-san-jose-all-inclusive/02-Suite/01.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/03-grand-san-jose-all-inclusive/02-Suite/02.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/03-grand-san-jose-all-inclusive/02-Suite/03.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/03-grand-san-jose-all-inclusive/02-Suite/04.webp'),
                                        text: "",
                                    },
                                ],
                                name: "Deluxe Double or King Suite",
                                priceUSD: 699,
                                additionalNightPrice: '',
                                description:
                                    "<p>This elegant room is decorated in a beautiful contemporary style to enjoy a stay full of comfort. Guest rooms and suites are decorated in a striking, contemporary European design, with dark woods, plush white bedding and curtains and red accents for a touch of traditional Mexico.</p>",
                                galleryDescription: "Be our guest and see it for yourself.",
                                features: [
                                    {
                                        feature: "A king size bed or 2 double beds",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Air Conditioning",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Balcony/Terrace",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Bathroom Amenities",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Cable/Satellite",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Coffee maker",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Hair dryer",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Iron",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Ironing board",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Minibar",
                                        extraCharge: true
                                    },
                                    {
                                        feature: "Facial Mirror",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Non-smoking rooms",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "LCD/Plasma display",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Room Service",
                                        extraCharge: true
                                    },
                                    {
                                        feature: "Security Box",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Shower head (Shower)",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Smoke Detectors",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Sofa bed",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Phone",
                                        extraCharge: true
                                    },
                                    {
                                        feature: "Wake-up Service",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Wireless Internet",
                                        extraCharge: false
                                    }]

                            },
                            amenities: {
                                gallery: [
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/03-grand-san-jose-all-inclusive/03-Amenities/06.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/03-grand-san-jose-all-inclusive/03-Amenities/02.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/03-grand-san-jose-all-inclusive/03-Amenities/03.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/03-grand-san-jose-all-inclusive/03-Amenities/04.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/03-grand-san-jose-all-inclusive/03-Amenities/05.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/03-grand-san-jose-all-inclusive/03-Amenities/06.webp')
                                    },
                                ],
                                description: [
                                    {
                                        amenitie: "Four heated pools, one of which is for adults only",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Aqua-aerobics",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Volleyball in the pool or sand",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Water Polo",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Whale watching from the beach",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Yoga",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Salsa classes",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Set of horseshoes",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Ping Pong",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Beach soccer",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Football table",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Surfing available at nearby beach only five minutes away",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Local musicians, emerging artists and events",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Karaoke night twice a week",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Live music in the lobby",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Movie night",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Theme Nights at Sports Bar Time Out",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Fitness center",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Wedding Services",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "Spa Services",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Kids Club",
                                        extraCharge: false
                                    },
                                ]
                            },
                            location: {
                                url: 'https://goo.gl/maps/hGVREcRbdUntwKSN7',
                                map:
                                    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14687.002870441318!2d-109.7136719!3d23.0329234!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x50eb583669188a8!2sKrystal%20Grand%20Los%20Cabos!5e0!3m2!1ses-419!2smx!4v1661903487472!5m2!1ses-419!2smx'
                            },
                        },
                        {
                            id: 8,
                            name: "Cabo El Faro All Inclusive",
                            slug: "cabo-el-faro-all-inclusive",
                            image: { url: require('@/assets/images/cabo-san-lucas/packages/04-cabo-faro-all-inclusive/01-Resort/05.webp') },
                            includeInHome: false,
                            soldOut: false,
                            nights: 4,
                            adults: 2,
                            children: 2,
                            childrenAgeRange: 'Ask about including children',
                            plan: "All Inclusive",
                            retailPriceUSD: 1991,
                            priceUSD: 699,
                            additionalNightPrice: 275,
                            status: '',
                            vip: {
                                isVip: true,
                                vipDiscount: 100
                            },
                            offer: {
                                rating: 5,
                                includes: [
                                    "This amazing package includes 5 days and 4 nights for 2 adults.",
                                    "All Inclusive plan.",
                                ],
                                description:
                                    "<p>This all-inclusive resort is located in front of the stunning Sea of Cortes. It is a resort for enjoying the tranquility of Los Cabos, the chosen destination of many famous Hollywood stars. Within just 2 miles away, you can find San Jose del Cabo, a colonial town well known for its cultural ambiance and varied gastronomical offering.</p><p>This luxurious resort is just 5 minutes from downtown San José del Cabo. It offers comfortable rooms and suites where you can pamper yourself and enjoy a well-earned rest while you take in the stunning views of the ocean.</p>",
                                allInclusive: [
                                    "All Meals Daily, including Breakfast, Lunch, and Dinner",
                                    "Snacks Served All Day",
                                    "Weekly Theme Dinners",
                                    "Unlimited Beer, Wine, and Spirits",
                                    "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                                    "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                                    "Supervised Activities for Children Ages 4-12",
                                    "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                                    "Free yoga lessons are offered daily at 9am on the beach.",
                                ],
                            },
                            resort: {
                                gallery: [
                                    { url: require('@/assets/images/cabo-san-lucas/packages/04-cabo-faro-all-inclusive/01-Resort/05.webp') },
                                    { url: require('@/assets/images/cabo-san-lucas/packages/04-cabo-faro-all-inclusive/01-Resort/03.webp') },
                                    { url: require('@/assets/images/cabo-san-lucas/packages/04-cabo-faro-all-inclusive/01-Resort/04.webp') },
                                    { url: require('@/assets/images/cabo-san-lucas/packages/04-cabo-faro-all-inclusive/01-Resort/06.webp') },
                                    { url: require('@/assets/images/cabo-san-lucas/packages/04-cabo-faro-all-inclusive/01-Resort/02.webp') }
                                ],
                                description:
                                    "<p>This is a Premium Level resort located along the breathtaking waters of the Sea of Cortés. It is the perfect resort for enjoying a bit of peace and quiet, thanks to its exclusive location just 0.6 miles from San José, a colonial town famous for its cultural ambiance and cuisine.The resort offers 350 luxurious rooms and suites, all of which have a private balcony overlooking the Sea of Cortés and come fully equipped with first-class amenities to ensure a memorable stay.</p>",
                            },
                            rooms:
                            {
                                gallery: [
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/04-cabo-faro-all-inclusive/02-Suite/01.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/04-cabo-faro-all-inclusive/02-Suite/03.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/04-cabo-faro-all-inclusive/02-Suite/04.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/04-cabo-faro-all-inclusive/02-Suite/05.webp'),
                                        text: "",
                                    },
                                ],
                                name: "Superior Room Suite",
                                priceUSD: 699,
                                additionalNightPrice: '',
                                description:
                                    "<p>The large and bright rooms Superior Rooms provide 495 sq ft of space and offer cheerful decor and a welcoming terrace with views of the stunning gardens. They are the perfect accommodation for couples or families who want to enjoy a comfortable vacation next to the beach. They have one wide range of quality amenities and amenities that make your stay an experience full of pleasant sensations.</p>",
                                galleryDescription: "Be our guest and see it for yourself.",
                                features: [
                                    {
                                        feature: "Radio alarm clock",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Telephone",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Air conditioning",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "42'' LED TV",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Hairdryer",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Iron and ironing board",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Mirror",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Digital safe",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Coffee machine",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Minibar ",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Fan",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "King-size bed or 2 double beds",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Magnifying Glass in the bathroom",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Shower",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Wireless wifi",
                                        extraCharge: false

                                    },

                                ]

                            },
                            amenities: {
                                gallery: [
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/04-cabo-faro-all-inclusive/03-Amenities/01.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/04-cabo-faro-all-inclusive/03-Amenities/02.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/04-cabo-faro-all-inclusive/03-Amenities/03.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/04-cabo-faro-all-inclusive/03-Amenities/04.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/04-cabo-faro-all-inclusive/03-Amenities/05.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cabo-san-lucas/packages/04-cabo-faro-all-inclusive/03-Amenities/06.webp')
                                    },
                                ],
                                description: [
                                    {
                                        amenitie: "Las Olas Restaurant",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Albatross International Restaurant",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "La Góndola Restaurant",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Bars",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Spa Services",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Kids Club",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Two spacious pools",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "One central pool",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Infinity pool with sea views",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Hot Tubs area",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Adults only hot tub",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Daily activity program",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Fitness center",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Tennis court",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Racquetball court",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Jet ski",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "Golf course",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Wedding Services",
                                        extraCharge: true
                                    },

                                ]
                            },
                            location: {
                                url: 'https://goo.gl/maps/vA3S19nHQeFcpLe88',
                                map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.4173992117376!2d-109.69933168503174!3d23.045154184941612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86af509233e53db1%3A0x5000f4dac8284a72!2sBarcel%C3%B3%20Gran%20Faro%20Los%20Cabos!5e0!3m2!1ses-419!2smx!4v1661904352345!5m2!1ses-419!2smx"
                            },
                        },
                    ],
                    physical: {
                        lon: -109.9200604,
                        lat: 22.8938884,
                    }
                },
                {
                    id: 3,
                    name: "Cancun",
                    slug: "cancun",
                    image: require('@/assets/images/cancun/destination/01.webp'),
                    video: {
                        poster: "https://media.tagembed.com/post/43792/69585/101567/17893170194650197.png",
                        url: "https://media.tagembed.com/post/43792/69585/101567/17893170194650197.mp4"
                    },
                    description:
                        "<p>Cancun is the vacation of all vacations. It was just a small sand barrier when 35 years ago plans began to develop it into the vacation haven of the 21st century. Today Cancun features magnificent world-class resorts, hotels, clubs and shopping malls stretching for miles along pristine white-sand beaches. </p><p>Cancun offers something for everyone, from all-day beach parties and wild nights in the clubs, to isolated tranquil islands and fun in the sun for the whole family. From Cancun as your base, you can visit amazing archeological sites such as Tulum, and Chichen Itza, as well as day trips to the beaches and reefs of Isla Mujeres, Playa del Carmen and Cozumel. Take a snorkeling tour or a fun cruise and discover the wonders of the world’s second largest reef, then put on your dancing shoes for a night on the town</p>",
                    packages: [
                        {
                            id: 5,
                            name: "Cancun Palm All Inclusive",
                            slug: "cancun-palm-all-inclusive",
                            image: { url: require('@/assets/images/cancun/packages/01-cancun-palm-all-inclusive/01-Resort/01.webp') },
                            includeInHome: false,
                            soldOut: false,
                            nights: 4,
                            adults: 2,
                            children: 2,
                            childrenAgeRange: 'Ask about including children',
                            plan: "All Inclusive",
                            retailPriceUSD: 1679,
                            priceUSD: 699,
                            additionalNightPrice: '',
                            status: '',
                            vip: {
                                isVip: true,
                                vipDiscount: 100
                            },
                            offer: {
                                rating: 5,
                                includes: [
                                    "This amazing package includes 5 days and 4 nights for 2 adults.",
                                    "All Inclusive plan.",
                                ],
                                description:
                                    "<p>Located in the Cancun Hotel Zone, the Oasis Palm hotel is perfect for a family vacation or a romantic getaway. It offers a full All-Inclusive program with recreational activities and plenty of entertainment for young and old. The comfortable guestrooms overlook the ocean or the lagoon and are housed in two eight-story buildings that are shaped like a pyramid. It also has an excellent Kids Club with its own swimming pool and slide, outdoor playground, Internet room, movies, video game area, lounge, craft room and spa.</p> ",
                                allInclusive: [
                                    "All Meals Daily, including Breakfast, Lunch, and Dinner",
                                    "Snacks Served All Day",
                                    "Weekly Theme Dinners",
                                    "Unlimited Beer, Wine, and Spirits",
                                    "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                                    "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                                    "Supervised Activities for Children Ages 4-12",
                                    "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                                    "Free yoga lessons are offered daily at 9am on the beach.",
                                ],
                            },
                            resort: {
                                gallery: [
                                    { url: require('@/assets/images/cancun/packages/01-cancun-palm-all-inclusive/01-Resort/02.webp') },
                                    { url: require('@/assets/images/cancun/packages/01-cancun-palm-all-inclusive/01-Resort/03.webp') },
                                    { url: require('@/assets/images/cancun/packages/01-cancun-palm-all-inclusive/01-Resort/04.webp') },
                                    { url: require('@/assets/images/cancun/packages/01-cancun-palm-all-inclusive/01-Resort/05.webp') },
                                    { url: require('@/assets/images/cancun/packages/01-cancun-palm-all-inclusive/01-Resort/06.webp') }
                                ],
                                description:
                                    "<p>Comfort, fun, countless activities, and the friendliest attention; all these and more await at Oasis Palm, the perfect resort to enjoy an unforgettable family vacation. With beautiful and cozy family rooms, and some more showing fun themes that kids love, this Cancun family hotel allows guests of all ages to live their own adventure, with recreational activities and water sports on a fantastic white sand beach splashed by turquoise blue waters.</p>",
                            },
                            rooms:
                            {
                                gallery: [
                                    {
                                        url: require('@/assets/images/cancun/packages/01-cancun-palm-all-inclusive/02-Suite/01.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/01-cancun-palm-all-inclusive/02-Suite/02.webp'),
                                        text: "",
                                    },
                                ],
                                name: "Standard Suite",
                                priceUSD: 699,
                                additionalNightPrice: '',
                                description:
                                    "<p>The themed proposal of our standard rooms, shows fun decoration filled with imagination, where the youngest members of the family will enjoy and add a touch of adventure to their vacation; moreover, for your peace of mind, these are connected to the adult rooms.</p>",
                                galleryDescription: "Be our guest and see it for yourself.",
                                features: [
                                    {
                                        feature: "Express Check out",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "1 King size bed or 2 double beds",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Bath amenities and towels",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Iron and Ironing board (Upon request)",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Safety box",
                                        extraCharge: true
                                    },
                                    {
                                        feature: "Flat screen",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Air conditioning",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Hair dryer",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Radio Alarm clock",
                                        extraCharge: false
                                    },
                                ]
                            },
                            amenities: {
                                gallery: [
                                    {
                                        url: require('@/assets/images/cancun/packages/01-cancun-palm-all-inclusive/03-Amenities/01.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/01-cancun-palm-all-inclusive/03-Amenities/02.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/01-cancun-palm-all-inclusive/03-Amenities/03.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/01-cancun-palm-all-inclusive/03-Amenities/04.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/01-cancun-palm-all-inclusive/03-Amenities/05.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/01-cancun-palm-all-inclusive/03-Amenities/06.webp')
                                    },
                                ],
                                description: [
                                    {
                                        amenitie: "Entertainment.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Tabaqueria: Boutique with crafts, souvenirs and snacks.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Restaurants: special areas for children.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Romantic dinner in front of the beach.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Oasis Plus Customer Service.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Swimming pools",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Pool areas for children.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Kayaks",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Sensoria chill out & spa",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Kids club and family interaction areas.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Convention rooms and business center (3 rooms | 400pax).",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Viajes Caribe Maya: Tours y Excursions",
                                        extraCharge: false
                                    },
                                ]
                            },
                            location: {
                                url: 'https://goo.gl/maps/Yhb1KuGudJuCcacx8',
                                map: '//www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11239845.441806225!2d-93.86972523448354!3d18.545357597830623!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdb216047b680eee3!2sOasis%20Palm%20Hotel!5e1!3m2!1ses-419!2smx!4v1661963298742!5m2!1ses-419!2smx'
                            },
                        },
                        {
                            id: 6,
                            name: "Palmar Cancun All Inclusive",
                            slug: "palmar-cancun-all-inclusive",
                            image: { url: require('@/assets/images/cancun/packages/02-palmar-cancun-all-inclusive/01-Resort/05.webp') },
                            includeInHome: true,
                            soldOut: false,
                            nights: 4,
                            adults: 2,
                            children: 2,
                            childrenAgeRange: 'Ask about including children',
                            plan: "All Inclusive",
                            retailPriceUSD: 1497,
                            priceUSD: 699,
                            additionalNightPrice: 249,
                            status: '',
                            vip: {
                                isVip: true,
                                vipDiscount: 100
                            },
                            offer: {
                                rating: 5,
                                includes: [
                                    "This amazing package includes 5 days and 4 nights for 2 adults.",
                                    "All Inclusive plan.",
                                ],
                                description:
                                    "<p>The resort in is a brand new 5-star Resort featuring deluxe garden view studio suites in a unique 9-storey U-shaped building with access via exterior corridors. All rooms are decorated with marble floors, granite countertops and full furnishings with finely crafted wood furniture.  Feel the luxury as soon as you arrive.</p> ",
                                allInclusive: [
                                    "All Meals Daily, including Breakfast, Lunch, and Dinner",
                                    "Snacks Served All Day",
                                    "Weekly Theme Dinners",
                                    "Unlimited Beer, Wine, and Spirits",
                                    "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                                    "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                                    "Supervised Activities for Children Ages 4-12",
                                    "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                                    "Free yoga lessons are offered daily at 9am on the beach.",
                                ],
                            },
                            resort: {
                                gallery: [
                                    { url: require('@/assets/images/cancun/packages/02-palmar-cancun-all-inclusive/01-Resort/06.webp') },
                                    { url: require('@/assets/images/cancun/packages/02-palmar-cancun-all-inclusive/01-Resort/03.webp') },
                                    { url: require('@/assets/images/cancun/packages/02-palmar-cancun-all-inclusive/01-Resort/04.webp') },
                                    { url: require('@/assets/images/cancun/packages/02-palmar-cancun-all-inclusive/01-Resort/05.webp') },
                                    { url: require('@/assets/images/cancun/packages/02-palmar-cancun-all-inclusive/01-Resort/02.webp') }
                                ],
                                description:
                                    "<p>The resort is the pinnacle of luxury in Cancun, offering amazing 5-star and 4 diamonds service, extravagant amenities, and plenty of activities to keep your adventurous side entertained. When you visit the resort, expect to feel at home immediately in unprecedented comfort. This is so much more than a resort; this is your second home.</p>",
                            },
                            rooms:
                            {
                                gallery: [
                                    {
                                        url: require('@/assets/images/cancun/packages/02-palmar-cancun-all-inclusive/02-Suite/01.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/02-palmar-cancun-all-inclusive/02-Suite/02.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/02-palmar-cancun-all-inclusive/02-Suite/03.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/02-palmar-cancun-all-inclusive/02-Suite/04.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/02-palmar-cancun-all-inclusive/02-Suite/05.webp'),
                                        text: "",
                                    },
                                ],
                                name: "Bedroom Suite Garden View",
                                priceUSD: 699,
                                additionalNightPrice: '',
                                description:
                                    "<p>Book with us to escape to the Caribbean and embark on an enchanting getaway. This spacious 1152-square-foot suite features elegant décor with warm earthy tones punctuated by vibrant splashes of color to create a soothing atmosphere.</p>",
                                galleryDescription: "Be our guest and see it for yourself.",
                                features: [
                                    {
                                        feature: "Air-conditioning",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Connecting rooms are available",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Private bedroom with jacuzzi and balcony",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Spacious living room",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Private bedroom with whirlpool tub and balcony",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "50-inch smart TV",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Premium television channels",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "State-of-the-art kitchen",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Washer and dryer",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Beautiful view of the gardens",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Walk-in closet",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Whirlpool tub",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Separate Shower",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Twin vanities",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Luxury bath amenities",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Hair dryer",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Robes & slippers",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Alarm Clock",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "24-Hour Room service",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Fully-stocked wet bar",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Coffee/tea maker",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Ironing table and iron",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "High-speed wireless and wired Internet access",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "In-room safe",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Daily housekeeping",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Nespresso® machine",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Artisian toiletries",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "  Multiline phone ",
                                        extraCharge: false
                                    },]

                            },
                            amenities: {
                                gallery: [
                                    {
                                        url: require('@/assets/images/cancun/packages/02-palmar-cancun-all-inclusive/03-Amenities/01.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/02-palmar-cancun-all-inclusive/03-Amenities/02.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/02-palmar-cancun-all-inclusive/03-Amenities/03.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/02-palmar-cancun-all-inclusive/03-Amenities/04.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/02-palmar-cancun-all-inclusive/03-Amenities/05.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/02-palmar-cancun-all-inclusive/03-Amenities/06.webp')
                                    },
                                ],
                                description: [
                                    {
                                        amenitie: "24-hour front desk",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "ATM/banking",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Babysitting or childcare",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Banquet facilities",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Concierge desk",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Currency exchange",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Doorman/doorwoman",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Dry cleaning/laundry service",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Elevator",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Event catering",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Fitness facilities",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Gift shops or newsstand",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Beach bar",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Room service (24 hours)",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Security guard",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Tours/ticket assistance",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Wireless Internet access",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Children's club",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Massage - spa treatment room(s)",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Medical assistance available",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Secure parking",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Bilingual staff",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Free parking",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Shopping on site",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Spa services on site",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Swimming pool - children's",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Swimming pools - outdoor",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Beauty services",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Wedding services",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Wheelchair accessible",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Grocery/convenience store",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Beach/pool umbrellas",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Porter/bellhop",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Luggage storage",
                                        extraCharge: false
                                    },
                                ]
                            },
                            location: {
                                url: 'https://goo.gl/maps/zDcZDQ42TzHDUgT86',
                                map: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14875.995006136518!2d-86.8038489!3d21.2318981!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6d2a3c7f61e592a6!2sVilla%20del%20Palmar%20Cancun%20Luxury%20Beach%20Resort%20%26%20Spa!5e0!3m2!1ses-419!2smx!4v1661968408396!5m2!1ses-419!2smx'
                            },
                        },
                        {
                            id: 7,
                            name: "Grand Palm All Inclusive",
                            slug: "grand-palm-all-inclusive",
                            image: { url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/01-Resort/01.webp') },
                            includeInHome: false,
                            soldOut: false,
                            nights: 4,
                            adults: 2,
                            children: 2,
                            childrenAgeRange: 'Ask about including children',
                            plan: "All Inclusive",
                            retailPriceUSD: 1987,
                            priceUSD: 699,
                            additionalNightPrice: 299,
                            status: '',
                            vip: {
                                isVip: true,
                                vipDiscount: 100
                            },
                            offer: {
                                rating: 5,
                                includes: [
                                    "This amazing package includes 5 days and 4 nights for 2 adults.",
                                    "All Inclusive plan.",
                                ],
                                description:
                                    "<p>Grand Palm Cancun is an all-inclusive family resort and spa that is conveniently located at the north entrance to Cancun`s hotel zone. The Grand Palm sits within walking distance of shopping, bars and restaurants and features an on-site spa when it`s time to relax after a fun-filled day in Cancun. The Grand Palm offers endless activities without compromising the areas for rest and relaxation. The Kids Club offers a fully supervised secure environment for the children with a trained staff and numerous activity rooms. All age groups have many choices of activities including a kid’s own pool with water slides, restaurant, rest areas, overnight camp and amphitheater.</p> ",
                                allInclusive: [
                                    "All Meals Daily, including Breakfast, Lunch, and Dinner",
                                    "Snacks Served All Day",
                                    "Weekly Theme Dinners",
                                    "Unlimited Beer, Wine, and Spirits",
                                    "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                                    "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                                    "Supervised Activities for Children Ages 4-12",
                                    "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                                    "Free yoga lessons are offered daily at 9am on the beach.",
                                ],
                            },
                            resort: {
                                gallery: [
                                    { url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/01-Resort/02.webp') },
                                    { url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/01-Resort/03.webp') },
                                    { url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/01-Resort/04.webp') },
                                    { url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/01-Resort/05.webp') },
                                    { url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/01-Resort/06.webp') }
                                ],
                                description:
                                    "<p>The Grand Palm is all about the kid-stuff: endless entertainment, family-friendly accommodations, child-centric restaurants and a Kids Club that`s cool as can be. But the Grand Palm also has a pampered, indulgent side, too, as a visit to the Kin Ha Spa shows there's always plenty of fun stuff for parents to do while the kids are having fun. Grand Palm is like the sister resort, Grand Palm but a bit more upscale, with the extra amenities you’re looking for. At the Grand Palm, the dancing, dining and decor reflect the culture of Cancun, with delicious Mexican food in the cantina and lots of activities for everyone.</p>",
                            },
                            rooms:
                            {
                                gallery: [
                                    {
                                        url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/02-Suite/01.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/02-Suite/02.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/02-Suite/03.webp'),
                                        text: "",
                                    },
                                ],
                                name: "Grand Standard Suite",
                                priceUSD: 699,
                                additionalNightPrice: '',
                                description:
                                    "<p>This is a unique way of spending your family vacation in Cancun, while enjoying an all-inclusive stay in a charming atmosphere. With its rustic style and friendly personality, the Grand Standard room provides comfortable air-conditioned areas to cool down from the Caribbean sun.</p>",
                                galleryDescription: "Be our guest and see it for yourself.",
                                features: [
                                    {
                                        feature: "1 King size bed or 2 double beds.",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Bath amenities and towels.",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "WIFI: 1 code per room | USB ports.",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Iron and Ironing board.",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Safety box.",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Flat screen.",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Air conditioning.",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Hair dryer.",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Coffee machine.",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Minibar",
                                        extraCharge: false
                                    }
                                ]
                            },
                            amenities: {
                                gallery: [
                                    {
                                        url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/03-Amenities/01.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/03-Amenities/02.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/03-Amenities/03.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/03-Amenities/04.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/03-Amenities/05.webp')
                                    },
                                    {
                                        url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/03-Amenities/06.webp')
                                    },
                                ],
                                description: [
                                    {
                                        amenitie: "Restaurants",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Restaurants: some with special areas for children",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Romantic dinner in front of the beach",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Bars (Adults only)",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Oasis Plus Customer Service",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Special areas for children",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "1 infinity pool",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Yoga",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Zumba",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Aerobics",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Gym.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Kayaks",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Multipurpose court",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Sensoria chill out & spa",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Kids club and family interaction areas",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Family pirate ship tour",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Entertainment",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Convention rooms and business center (3 rooms | 400pax)",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Viajes Caribe Maya: Tours y Excursions",
                                        extraCharge: false
                                    },
                                ]
                            },
                            location: {
                                url: 'https://goo.gl/maps/YoRd3nqaDdhiCYWL7',
                                map: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14884.877233721858!2d-86.7837758!3d21.1436689!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdb216047b680eee3!2sOasis%20Palm%20Hotel!5e0!3m2!1ses-419!2smx!4v1661970080428!5m2!1ses-419!2smx'
                            },
                        },
                        // {
                        //     id: 7,
                        //     name: "Cancun Resort All Inclusive",
                        //     slug: "cancun-resort-all-inclusive",
                        //     image: { url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/01-Resort/01.webp') },
                        //     includeInHome: false,
                        //     soldOut: false,
                        //     nights: 4,
                        //     adults: 2,
                        //     children: 2,
                        //     childrenAgeRange: 'Ask about including children',
                        //     plan: "All Inclusive",
                        //     retailPriceUSD: 1987,
                        //     priceUSD: 699,
                        //     additionalNightPrice: 299,
                        //     status: '',
                        //     vip: {
                        //         isVip: true,
                        //         vipDiscount: 100
                        //     },
                        //     offer: {
                        //         rating: 5,
                        //         includes: [
                        //             "This amazing package includes 5 days and 4 nights for 2 adults.",
                        //             "All Inclusive plan.",
                        //         ],
                        //         description:
                        //             "<p>Grand Palm Cancun is an all-inclusive family resort and spa that is conveniently located at the north entrance to Cancun`s hotel zone. The Grand Palm sits within walking distance of shopping, bars and restaurants and features an on-site spa when it`s time to relax after a fun-filled day in Cancun. The Grand Palm offers endless activities without compromising the areas for rest and relaxation. The Kids Club offers a fully supervised secure environment for the children with a trained staff and numerous activity rooms. All age groups have many choices of activities including a kid’s own pool with water slides, restaurant, rest areas, overnight camp and amphitheater.</p> ",
                        //         allInclusive: [
                        //             "All Meals Daily, including Breakfast, Lunch, and Dinner",
                        //             "Snacks Served All Day",
                        //             "Weekly Theme Dinners",
                        //             "Unlimited Beer, Wine, and Spirits",
                        //             "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                        //             "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                        //             "Supervised Activities for Children Ages 4-12",
                        //             "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                        //             "Free yoga lessons are offered daily at 9am on the beach.",
                        //         ],
                        //     },
                        //     resort: {
                        //         gallery: [
                        //             { url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/01-Resort/02.webp') },
                        //             { url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/01-Resort/03.webp') },
                        //             { url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/01-Resort/04.webp') },
                        //             { url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/01-Resort/05.webp') },
                        //             { url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/01-Resort/06.webp') }
                        //         ],
                        //         description:
                        //             "<p>The Grand Palm is all about the kid-stuff: endless entertainment, family-friendly accommodations, child-centric restaurants and a Kids Club that`s cool as can be. But the Grand Palm also has a pampered, indulgent side, too, as a visit to the Kin Ha Spa shows there's always plenty of fun stuff for parents to do while the kids are having fun. Grand Palm is like the sister resort, Grand Palm but a bit more upscale, with the extra amenities you’re looking for. At the Grand Palm, the dancing, dining and decor reflect the culture of Cancun, with delicious Mexican food in the cantina and lots of activities for everyone.</p>",
                        //     },
                        //     rooms:
                        //     {
                        //         gallery: [
                        //             {
                        //                 url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/02-Suite/01.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/02-Suite/02.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/02-Suite/03.webp'),
                        //                 text: "",
                        //             },
                        //         ],
                        //         name: "Grand Standard Suite",
                        //         priceUSD: 699,
                        //         additionalNightPrice: '',
                        //         description:
                        //             "<p>This is a unique way of spending your family vacation in Cancun, while enjoying an all-inclusive stay in a charming atmosphere. With its rustic style and friendly personality, the Grand Standard room provides comfortable air-conditioned areas to cool down from the Caribbean sun.</p>",
                        //         galleryDescription: "Be our guest and see it for yourself.",
                        //         features: [
                        //             {
                        //                 feature: "1 King size bed or 2 double beds.",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Bath amenities and towels.",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "WIFI: 1 code per room | USB ports.",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Iron and Ironing board.",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Safety box.",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Flat screen.",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Air conditioning.",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Hair dryer.",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Coffee machine.",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Minibar",
                        //                 extraCharge: false
                        //             }
                        //         ]
                        //     },
                        //     amenities: {
                        //         gallery: [
                        //             {
                        //                 url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/03-Amenities/01.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/03-Amenities/02.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/03-Amenities/03.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/03-Amenities/04.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/03-Amenities/05.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/cancun/packages/03-grand-palm-cancun/03-Amenities/06.webp')
                        //             },
                        //         ],
                        //         description: [
                        //             {
                        //                 amenitie: "Restaurants",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Restaurants: some with special areas for children",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Romantic dinner in front of the beach",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Bars (Adults only)",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Oasis Plus Customer Service",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Special areas for children",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "1 infinity pool",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Yoga",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Zumba",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Aerobics",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Gym.",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Kayaks",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Multipurpose court",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Sensoria chill out & spa",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Kids club and family interaction areas",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Family pirate ship tour",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Entertainment",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Convention rooms and business center (3 rooms | 400pax)",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Viajes Caribe Maya: Tours y Excursions",
                        //                 extraCharge: false
                        //             },
                        //         ]
                        //     },
                        //     location: {
                        //         url: 'https://goo.gl/maps/YoRd3nqaDdhiCYWL7',
                        //         map: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14884.877233721858!2d-86.7837758!3d21.1436689!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdb216047b680eee3!2sOasis%20Palm%20Hotel!5e0!3m2!1ses-419!2smx!4v1661970080428!5m2!1ses-419!2smx'
                        //     },
                        // },
                    ],
                    physical: {
                        lat: 21.1617854,
                        lon: -86.8510468,
                    }
                },
                {
                    id: 4,
                    name: "Riviera Maya",
                    slug: "riviera-maya",
                    image: require('@/assets/images/riviera-maya/destination/02.webp'),
                    video: {
                        poster: "https://media.tagembed.com/post/43792/69585/101567/17967303415941313.png",
                        url: "https://media.tagembed.com/post/43792/69585/101567/17967303415941313.mp4"
                    },
                    description:
                        "<p>The Riviera Maya is the newest and most recent resort on the Mexican Caribbean Coast. It is a tropical paradise of crystal-clear waters that harbors true living treasures, some of the world's most beautiful beaches, and an exuberant tropical rainforest teeming with rare wildlife.</p><p>The Riviera Maya is the perfect place for a romantic hideaway, where you can do extreme sports or enjoy the coolest nighttime entertainment. Experience the Caribbean at its fullest, surrounded by magic places like the Sian Ka'an Nature Reserve, the Tulum archaeological site and hundreds of natural cenotes (sink holes that harbor several plant and animal species).</p>",
                    packages: [
                        {
                            id: 5,
                            name: "Esmeralda All Inclusive",
                            slug: "esmeralda-all-inclusive",
                            image: { url: require('@/assets/images/riviera-maya/packages/01-esmeralda-all-inclusive/01-Resort/02.webp') },
                            includeInHome: false,
                            soldOut: false,
                            nights: 4,
                            adults: 2,
                            children: 2,
                            childrenAgeRange: 'Ask about including children',
                            plan: "All Inclusive",
                            retailPriceUSD: 1899,
                            priceUSD: 699,
                            additionalNightPrice: 229,
                            status: '',
                            vip: {
                                isVip: true,
                                vipDiscount: 100
                            },
                            offer: {
                                rating: 5,
                                includes: [
                                    "This amazing package includes 5 days and 4 nights for 2 adults.",
                                    "All Inclusive plan.",
                                ],
                                description:
                                    "<p>The crystalline and turquoise waters of the Caribbean Sea bathe the coast of Playa del Carmen, a wonderful and natural place where this fantastic hotel is located in Mexico. Mexico is a different country and, on the Blue Bay Grand Esmeralda you will be able to see it, in its restaurants, its leisure services, its employees and, of course, the views that surround it.</p> ",
                                allInclusive: [
                                    "All Meals Daily, including Breakfast, Lunch, and Dinner",
                                    "Snacks Served All Day",
                                    "Weekly Theme Dinners",
                                    "Unlimited Beer, Wine, and Spirits",
                                    "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                                    "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                                    "Supervised Activities for Children Ages 4-12",
                                    "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                                    "Free yoga lessons are offered daily at 9am on the beach.",
                                ],
                            },
                            resort: {
                                gallery: [
                                    { url: require('@/assets/images/riviera-maya/packages/01-esmeralda-all-inclusive/01-Resort/02.webp') },
                                    { url: require('@/assets/images/riviera-maya/packages/01-esmeralda-all-inclusive/01-Resort/03.webp') },
                                    { url: require('@/assets/images/riviera-maya/packages/01-esmeralda-all-inclusive/01-Resort/04.webp') },
                                    { url: require('@/assets/images/riviera-maya/packages/01-esmeralda-all-inclusive/01-Resort/05.webp') },
                                    { url: require('@/assets/images/riviera-maya/packages/01-esmeralda-all-inclusive/01-Resort/06.webp') }
                                ],
                                description:
                                    "<p>This resort is one of the best Playa del Carmen hotels. Enjoy a luxury, 5-star experience in the Caribbean surrounded by lush jungle, mangrove swamps and outstanding views of the sea. An all-inclusive stay at this resort with the private beach, outstanding food and full spa, really is a stay in paradise.</p><p>The facilities include full spa, three swimming pools, private beach, golf club and a gym. It also has several restaurants and bars to enjoy world cuisine and a cocktail whilst watching the sun go down. Our highly trained chefs serve some of the finest French, Italian, Mexican & oriental food.</p>",
                            },
                            rooms:
                            {
                                gallery: [
                                    {
                                        url: require('@/assets/images/riviera-maya/packages/01-esmeralda-all-inclusive/02-Suite/01.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/riviera-maya/packages/01-esmeralda-all-inclusive/02-Suite/02.webp'),
                                        text: "",
                                    },
                                ],
                                name: "Deluxe Garden View Suite",
                                priceUSD: 699,
                                additionalNightPrice: '',
                                description:
                                    "<p>A fully equipped bright room for you to enjoy an unforgettable holiday in a unique setting. Spacious room with 2 double beds or 1 king size bed, elegant and finely furnished. From the balcony or terrace you can enjoy marvelous views.</p>",
                                galleryDescription: "Be our guest and see it for yourself.",
                                features: [
                                    {
                                        feature: "Wireless Wi-Fi",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Welcome attentions.",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Minibar",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "HD TV",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Phone",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Radio/ Alarm Clock",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Air conditioning and/or ceiling fan",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Pillow Menu",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Safe deposit box",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Ironing board and iron",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Hair dryer",
                                        extraCharge: false
                                    },
                                ]
                            },
                            amenities: {
                                gallery: [
                                    {
                                        url: require('@/assets/images/riviera-maya/packages/01-esmeralda-all-inclusive/03-Amenities/01.webp')
                                    },
                                    {
                                        url: require('@/assets/images/riviera-maya/packages/01-esmeralda-all-inclusive/03-Amenities/02.webp')
                                    },
                                    {
                                        url: require('@/assets/images/riviera-maya/packages/01-esmeralda-all-inclusive/03-Amenities/03.webp')
                                    },
                                    {
                                        url: require('@/assets/images/riviera-maya/packages/01-esmeralda-all-inclusive/03-Amenities/04.webp')
                                    },
                                    {
                                        url: require('@/assets/images/riviera-maya/packages/01-esmeralda-all-inclusive/03-Amenities/05.webp')
                                    },
                                    {
                                        url: require('@/assets/images/riviera-maya/packages/01-esmeralda-all-inclusive/03-Amenities/06.webp')
                                    },
                                ],
                                description: [
                                    {
                                        amenitie: "Reception 24h.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Concierge.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Room service.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Babysitting service.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Medical Service.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Parking.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Massage treatment.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Beauty treatments.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Hairdressing.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Towel service on the beach.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Laundry.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Currency exchange.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Restaurants",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Spa Services",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "3 swimming pool",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "1 pool for kids",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Fitness center",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Night Show",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Kids Club",
                                        extraCharge: false
                                    },
                                ]
                            },
                            location: {
                                url: 'https://goo.gl/maps/6f3reiwLUzUwMmaWA',
                                map: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14928.661244524521!2d-87.0175457!3d20.7035107!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xff9b4c2963a47f3e!2sBlueBay%20Grand%20Esmeralda!5e0!3m2!1ses-419!2smx!4v1661978094889!5m2!1ses-419!2smx'
                            },
                        },
                        // {
                        //     id: 6,
                        //     name: "Ocean Paradise All Inclusive",
                        //     slug: "ocean-paradise-all-inclusive",
                        //     image: { url: require('@/assets/images/riviera-maya/packages/02-ocean-paradise-all-inclusive/01-Resort/06.webp') },
                        //     includeInHome: false,
                        //     soldOut: false,
                        //     nights: 4,
                        //     adults: 2,
                        //     children: 2,
                        //     childrenAgeRange: '',
                        //     plan: "All Inclusive",
                        //     retailPriceUSD: 2000,
                        //     priceUSD: 699,
                        //     additionalNightPrice: 229,
                        //     status: 'sold out',
                        //     vip: {
                        //         isVip: false,
                        //         vipDiscount: 100
                        //     },
                        //     offer: {
                        //         rating: 5,
                        //         includes: [
                        //             "This amazing package includes 5 days and 4 nights for 2 adults.",
                        //             "All Inclusive plan.",
                        //         ],
                        //         description:
                        //             "<p>This is a newly-built five-star resort located on the Riviera Maya seafront, very close to Playa del Carmen. It is noteworthy for its huge size, in addition to its modern architecture and striking Mediterranean-inspired interior design. The resort benefits from multiple swimming pools, one of which is on the beach itself, just a few metres from the sea, as well as lush native vegetation, 10 restaurants, 12 bars and a sports area. It also features Privilege, Exclusive Rooms and Services, a Despacio Spa Centre, Dive It! diving centre and a wide range of entertainment options for the whole family. </p>",
                        //         allInclusive: [
                        //             "All Meals Daily, including Breakfast, Lunch, and Dinner",
                        //             "Snacks Served All Day",
                        //             "Weekly Theme Dinners",
                        //             "Unlimited Beer, Wine, and Spirits",
                        //             "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                        //             "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                        //             "Supervised Activities for Children Ages 4-12",
                        //             "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                        //             "Free yoga lessons are offered daily at 9am on the beach.",
                        //         ],
                        //     },
                        //     resort: {
                        //         gallery: [
                        //             { url: require('@/assets/images/riviera-maya/packages/02-ocean-paradise-all-inclusive/01-Resort/06.webp') },
                        //             { url: require('@/assets/images/riviera-maya/packages/02-ocean-paradise-all-inclusive/01-Resort/03.webp') },
                        //             { url: require('@/assets/images/riviera-maya/packages/02-ocean-paradise-all-inclusive/01-Resort/04.webp') },
                        //             { url: require('@/assets/images/riviera-maya/packages/02-ocean-paradise-all-inclusive/01-Resort/05.webp') },
                        //             { url: require('@/assets/images/riviera-maya/packages/02-ocean-paradise-all-inclusive/01-Resort/02.webp') }
                        //         ],
                        //         description:
                        //             "<p>This resort is one of the best Playa del Carmen hotels. Enjoy a luxury, 5-star experience in the Caribbean surrounded by lush jungle, mangrove swamps and outstanding views of the sea. An all-inclusive stay at this resort with the private beach, outstanding food and full spa, really is a stay in paradise.</p><p>The facilities include full spa, three swimming pools, private beach, golf club and a gym. It also has several restaurants and bars to enjoy world cuisine and a cocktail whilst watching the sun go down. Our highly trained chefs serve some of the finest French, Italian, Mexican & oriental food.</p>",
                        //     },
                        //     rooms:
                        //     {
                        //         gallery: [
                        //             {
                        //                 url: require('@/assets/images/riviera-maya/packages/02-ocean-paradise-all-inclusive/02-Suite/01.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/riviera-maya/packages/02-ocean-paradise-all-inclusive/02-Suite/02.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/riviera-maya/packages/02-ocean-paradise-all-inclusive/02-Suite/03.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/riviera-maya/packages/02-ocean-paradise-all-inclusive/02-Suite/04.webp'),
                        //                 text: "",
                        //             },
                        //         ],
                        //         name: "Junior Suite Standard",
                        //         priceUSD: 699,
                        //         additionalNightPrice: '',
                        //         description:
                        //             "<p>Is located in front of the water park for children and right next to the main buffet restaurant. The max capacity for this unit is 4 adults and 1 child. It can be garden or pool view based on availability.</p>",
                        //         galleryDescription: "Be our guest and see it for yourself.",
                        //         features: [
                        //             {
                        //                 feature: "Safety box",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Closet",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "LCD flat screen TV with international channels",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Full bathroom with bathtub and shower",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Magnifying mirror",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Hair dryer",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Free wireless Internet",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Minibar",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Room service from 11am-11pm",
                        //                 extraCharge: false
                        //             },
                        //         ]
                        //     },
                        //     amenities: {
                        //         gallery: [
                        //             {
                        //                 url: require('@/assets/images/riviera-maya/packages/02-ocean-paradise-all-inclusive/03-Amenities/01.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/riviera-maya/packages/02-ocean-paradise-all-inclusive/03-Amenities/02.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/riviera-maya/packages/02-ocean-paradise-all-inclusive/03-Amenities/03.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/riviera-maya/packages/02-ocean-paradise-all-inclusive/03-Amenities/04.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/riviera-maya/packages/02-ocean-paradise-all-inclusive/03-Amenities/05.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/riviera-maya/packages/02-ocean-paradise-all-inclusive/03-Amenities/06.webp')
                        //             },
                        //         ],
                        //         description: [
                        //             {
                        //                 amenitie: "Internet Corner",
                        //                 extraCharge: true
                        //             },
                        //             {
                        //                 amenitie: "Wireless WiFi",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Non-motorized water sports",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Dive Center and motorized water sports",
                        //                 extraCharge: true
                        //             },
                        //             {
                        //                 amenitie: "Theater",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Discotheque",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Bowling alley",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "13 meeting rooms",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Shopping mall",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Laundry service",
                        //                 extraCharge: true
                        //             },
                        //             {
                        //                 amenitie: "Babysitter service",
                        //                 extraCharge: true
                        //             },
                        //             {
                        //                 amenitie: "Exchange bureau and car rental",
                        //                 extraCharge: true
                        //             },
                        //             {
                        //                 amenitie: "5 pools, 1 for kids",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "10 restaurants",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "11 bars",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Mike’s Coffee",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Ice cream shop",
                        //                 extraCharge: false
                        //             },
                        //         ]
                        //     },
                        //     location: {
                        //         url: 'https://goo.gl/maps/j49qAM5LRQZJ5jTQ7',
                        //         map: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14931.916712104532!2d-87.0353118!3d20.6704261!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8cae5bc250ba1813!2sOcean%20Riviera%20Paradise!5e0!3m2!1ses-419!2smx!4v1661979229735!5m2!1ses-419!2smx'
                        //     },
                        // },
                    ],
                    physical: {
                        lat: 20.6308643,
                        lon: -87.0779503,
                    }
                },
                {
                    id: 5,
                    name: "Loreto",
                    slug: "loreto",
                    image: require('@/assets/images/loreto/destination/03.webp'),
                    video: {
                        poster: "https://media.tagembed.com/post/43792/69585/101567/18234938713145012.png",
                        url: "https://media.tagembed.com/post/43792/69585/101567/18234938713145012.mp4"
                    },
                    description:
                        "<p>A burst of excitement has taken over the Baja-beauty of the Islands of Loreto. Travelers are discovering that this well-maintained village offers the best of both worlds for tourists who long to escape to an 'authentic-Mexico' with settings that take you back-in-time along with ocean activities that rival that of any other modern coastal city. </p><p>For one, the Islands of Loreto boasts Mexico's largest marine preserve where dolphins, seals, turtles and sea lions all play in the sea. The underwater animal life alone rivals that of any world class aquarium. And for those who are partial to adventures on land; there is hiking, biking, and nature-tours that fill up a camera with pictures of radiant natural settings.</p>",
                    packages: [
                        {
                            id: 5,
                            name: "Palmar Loreto All Inclusive",
                            slug: "palmar-loreto-all-inclusive",
                            image: { url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/01-Resort/01.webp') },
                            includeInHome: true,
                            soldOut: false,
                            nights: 4,
                            adults: 2,
                            children: 2,
                            childrenAgeRange: 'Ask about including children',
                            plan: "All Inclusive",
                            retailPriceUSD: 1998,
                            priceUSD: 599,
                            additionalNightPrice: 235,
                            status: '',
                            vip: {
                                isVip: false,
                                vipDiscount: 0
                            },
                            offer: {
                                rating: 5,
                                includes: [
                                    "This amazing package includes 5 days and 4 nights for 2 adults.",
                                    "All Inclusive plan.",
                                ],
                                description:
                                    "<p>Welcome to the historic town of Loreto. A place where you'll find historic buildings dotted with bright green plant life, exotic flowers bursting out of the concrete in fresh-fish taco stands, and the enchanting Mision de Loreto- a mission-style church that was built in 1697.</p> ",
                                allInclusive: [
                                    "All Meals Daily, including Breakfast, Lunch, and Dinner",
                                    "Snacks Served All Day",
                                    "Weekly Theme Dinners",
                                    "Unlimited Beer, Wine, and Spirits",
                                    "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                                    "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                                    "Supervised Activities for Children Ages 4-12",
                                    "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                                    "Free yoga lessons are offered daily at 9am on the beach.",
                                ],
                            },
                            resort: {
                                gallery: [
                                    { url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/01-Resort/01.webp') },
                                    { url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/01-Resort/03.webp') },
                                    { url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/01-Resort/04.webp') },
                                    { url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/01-Resort/05.webp') },
                                    { url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/01-Resort/06.webp') }
                                ],
                                description:
                                    "<p>If you are looking for a luxury vacation with absolute relaxation, Villa del Palmar in the Loreto Islands is for you. With its tranquil beaches, majestic views, rich history and amazing natural habitat all around, you'll feel like you've stepped into paradise.</p><p>As the best all-inclusive hotel in Loreto, Villa del Palmar in Loreto Islands offers all the modern amenities you could wish for and truly exceptional service, without leaving aside the picturesque ambiance of traditional Mexico. This luxury beach resort in Loreto is the perfect getaway for families, friends, couples, honeymooners, golf lovers and more.</p>",
                            },
                            rooms:
                            {
                                gallery: [
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/02-Suite/01.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/02-Suite/03.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/02-Suite/04.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/02-Suite/05.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/02-Suite/02.webp'),
                                        text: "",
                                    },
                                ],
                                name: "Deluxe Suite",
                                priceUSD: 699,
                                additionalNightPrice: '',
                                description:
                                    "<p>Our Deluxe Suite is larger than a standard size hotel room with 60 square meters of expansive space. The room includes two queen size beds or one king size bed (subject to availability), a large bathroom, an LCD television, a full kitchenette with two-burner stove, mini refrigerator, coffee maker, blender, toaster and enough tableware for everyone staying in the room. Each Luxury Suite at Villa del Palmar in the Islands of Loreto has a spacious balcony that provides wonderful views of the Sierra de la Giganta Mountains, TPC Danzante Bay, or the resort's tropical gardens and beautiful pool.</p>",
                                galleryDescription: "Be our guest and see it for yourself.",
                                features: [
                                    {
                                        feature: "Air conditioning",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Climate control",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Flat screen television",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Premium TV channels",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Telephone",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Kitchenette",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Stove with two electric burners",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Cooking utensils and tableware",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Mini refrigerator",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Coffee maker",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Toaster",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Blender",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Microwave oven",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Hair dryer",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Alarm clock radio",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Iron and ironing board",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Safety box",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Spacious bathroom ",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Private balcony ",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Daily room cleaning",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Electronic/magnetic keys",
                                        extraCharge: false
                                    }
                                ]
                            },
                            amenities: {
                                gallery: [
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/03-Amenities/01.webp')
                                    },
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/03-Amenities/02.webp')
                                    },
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/03-Amenities/03.webp')
                                    },
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/03-Amenities/04.webp')
                                    },
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/03-Amenities/05.webp')
                                    },
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/03-Amenities/06.webp')
                                    },
                                ],
                                description: [
                                    {
                                        amenitie: "Five outdoor sea turtle-shaped swimming pools and Jacuzzi",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Four gourmet restaurants, bars and snack bar.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Ocean, mountain and golf course view suites.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "TPC Danzante Bay - a spectacular 18-hole golf course in Loreto designed for PGA Tour play.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "3,623 square meter Sabila Spa and Wellness Center.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Fitness Center",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Tennis Court",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Pickleball",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Danzante Nightclub",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Kids Club",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Palmita Market (Convenience Store)",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Non-motorized water activities (kayaks, paddle boards, snorkeling)",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Beach and pool activities throughout the day",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Nightly entertainment and theme nights",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "24-hour room service",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "24-hour front desk",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Bilingual staff",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "In-resort wireless Internet in public areas",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Babysitting service",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Parking and valet parking",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Wedding services",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Dry cleaning and laundry services",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Medical services",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Danzante Tours",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Car rental service",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Housekeeping service",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Wheelchair access",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "                Currency Exchange",
                                        extraCharge: false
                                    },
                                ]
                            },
                            location: {
                                url: 'https://goo.gl/maps/TJisFvTLQDmtpcz86',
                                map: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14378.249213135376!2d-111.2335281!3d25.7189121!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x328727e664043480!2sVilla%20del%20Palmar%20at%20The%20Islands%20of%20Loreto!5e0!3m2!1ses-419!2smx!4v1661982816137!5m2!1ses-419!2smx'
                            },
                        },
                        // {
                        //     id: 6,
                        //     name: "Loreto Bay All Inclusive",
                        //     slug: "loreto-bay-all-inclusive",
                        //     image: { url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/01-Resort/03.webp') },
                        //     includeInHome: false,
                        //     soldOut: false,
                        //     nights: 4,
                        //     adults: 2,
                        //     children: 2,
                        //     childrenAgeRange: '',
                        //     plan: "All Inclusive",
                        //     retailPriceUSD: 1261,
                        //     priceUSD: 699,
                        //     additionalNightPrice: '',
                        //     status: '',
                        //     vip: {
                        //         isVip: true,
                        //         vipDiscount: 100
                        //     },
                        //     offer: {
                        //         rating: 5,
                        //         includes: [
                        //             "This amazing package includes 5 days and 4 nights for 2 adults.",
                        //             "All Inclusive plan.",
                        //         ],
                        //         description:
                        //             "<p>In the heart of Loreto Bay on the Sea of Cortez enjoy the crystal-clear turquoise waters and bask in the golden sun. The Beautiful Mountain range of the Sierra La Giganta forms an amazing backdrop to this amazing picturesque view. Let yourself go into a Zen of relaxation in this magical atmosphere. Explore the charming and enchanted streets of Loreto and take yourself back in time to the simpler less complicated days of life. This is the definitely the vacation that will take you away from it all.</p>",
                        //         allInclusive: [
                        //             "All Meals Daily, including Breakfast, Lunch, and Dinner",
                        //             "Snacks Served All Day",
                        //             "Weekly Theme Dinners",
                        //             "Unlimited Beer, Wine, and Spirits",
                        //             "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                        //             "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                        //             "Supervised Activities for Children Ages 4-12",
                        //             "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                        //             "Free yoga lessons are offered daily at 9am on the beach.",
                        //         ],
                        //     },
                        //     resort: {
                        //         gallery: [
                        //             { url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/01-Resort/02.webp') },
                        //             { url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/01-Resort/03.webp') },
                        //             { url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/01-Resort/04.webp') },
                        //             { url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/01-Resort/05.webp') },
                        //             { url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/01-Resort/06.webp') }
                        //         ],
                        //         description:
                        //             "<p>Loreto Bay Golf Resort & Spa is nestled on the shores of the Mar de Cortes, in the exclusive Nopolo Bay. The Sierra La Giganta Mountains and the vast desert join together to create a magical setting and a heavenly ambiance.</p><p>The beauty of this paradise emerges from each grain of sand, each wave and also from the majestic rock formations that create magnificent scenic views. In this exclusive enclave, you will enjoy the most attentive and sophisticated service.</p>",
                        //     },
                        //     rooms:
                        //     {
                        //         gallery: [
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/02-Suite/01.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/02-Suite/02.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/02-Suite/03.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/02-Suite/04.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/02-Suite/05.webp'),
                        //                 text: "",
                        //             },
                        //         ],
                        //         name: "Junior Suite Standard",
                        //         priceUSD: 699,
                        //         additionalNightPrice: '',
                        //         description:
                        //             "<p>Spacious and comfortable Deluxe rooms for up to 4 people, combining fine woods, marble and terracotta to create a pleasant and relaxing atmosphere. In them you can enjoy a spectacular view of the Sea of Cortez from the balcony. All rooms have king size beds or two double beds, spacious bathrooms, luxury amenities, satellite TV, free Wi-Fi, safe deposit box and coffee maker with complimentary coffee service. Rooms for people with disabilities are also available.</p>",
                        //         galleryDescription: "Be our guest and see it for yourself.",
                        //         features: [
                        //             {
                        //                 feature: "Free Wi-Fi",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Smoke detector",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Iron and ironing board",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Satellite TV",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Air conditioning",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Wake-up service",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Shower",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Balcony",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Hair dryer",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Crib",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Safety box",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Telephone",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Bathroom amenities",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Room service",
                        //                 extraCharge: false
                        //             },
                        //         ]
                        //     },
                        //     amenities: {
                        //         gallery: [
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/03-Amenities/01.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/03-Amenities/02.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/03-Amenities/03.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/03-Amenities/04.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/03-Amenities/05.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/03-Amenities/06.webp')
                        //             },
                        //         ],
                        //         description: [
                        //             {
                        //                 amenitie: "Golf course",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Jacuzzi",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "General swimming pool",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Children's pool",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "2 restaurants",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Events room",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Wedding services",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Spa services",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Non-motorized activities",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Sport Bars",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Tennis courts",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Fitness center",
                        //                 extraCharge: false
                        //             },
                        //         ]
                        //     },
                        //     location: {
                        //         url: 'https://goo.gl/maps/56k4Sfybf4qyTfww9',
                        //         map: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14353.776349382464!2d-111.3481078!3d25.9206394!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4387a1eec45d00e4!2sHotel%20Loreto%20Bay%20Golf%20Resort%20%26%20Spa!5e0!3m2!1ses!2smx!4v1674607354408!5m2!1ses!2smx'
                        //     },
                        // },
                    ],
                    physical: {
                        lat: 26.0097913,
                        lon: -111.3452523
                    }
                },
                {
                    id: 6,
                    name: "Loreto",
                    slug: "loreto-es",
                    image: require('@/assets/images/loreto/destination/03.webp'),
                    video: {
                        poster: "https://media.tagembed.com/post/43792/69585/101567/18234938713145012.png",
                        url: "https://media.tagembed.com/post/43792/69585/101567/18234938713145012.mp4"
                    },
                    description:
                        "<p>Una ola de emoción ha cubierto las Islas de Loreto ubicadas en la deslumbrante Baja. Los viajeros están descubriendo que este pueblo bien preservado ofrece lo mejor de dos mundos para turistas que buscan escapar y también experimentar el “Auténtico México” con paisajes que evocan tiempos más simples, así como actividades acuáticas que igualan aquellas de cualquier otra ciudad costeña moderna.</p><p>Las Islas de Loreto se jactan de albergar la reserva marina más grande de México donde coexisten tanto delfines, como focas, tortugas y lobos marinos. Tan solo la fauna bajo el agua empata con aquella de cualquier acuario de renombre. Y para los amantes del turismo de aventura Loreto ofrece senderismo, paseos en bicicleta y recorridos inmersos en la naturaleza que llenarán tu cámara de paisajes fascinantes.</p>",
                    packages: [
                        {
                            id: 6,
                            name: "Palmar Loreto Todo Incluido",
                            slug: "palmar-loreto-all-inclusive-es",
                            image: { url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/01-Resort/01.webp') },
                            includeInHome: true,
                            soldOut: false,
                            nights: 4,
                            adults: 2,
                            children: 2,
                            childrenAgeRange: 'Ask about including children',
                            plan: "All Inclusive",
                            retailPriceUSD: 32000,
                            priceUSD: 10800,
                            additionalNightPrice: 4500,
                            status: '',
                            vip: {
                                isVip: true,
                                vipDiscount: 1801
                            },
                            offer: {
                                rating: 5,
                                includes: [
                                    "This amazing package includes 5 days and 4 nights for 2 adults.",
                                    "All Inclusive plan.",
                                ],
                                description:
                                    "<p>Bienvenido al histórico pueblo de Loreto. Un lugar donde convergen edificaciones históricas decoradas con el verde vibrante de la flora local, flores exóticas saliendo del concreto y puestos de tacos de pescado fresco en un encantador estilo que recuerda a las misiones que ahí se asentaron y construyeron la iglesia en 1697.</p> ",
                                allInclusive: [
                                    "All Meals Daily, including Breakfast, Lunch, and Dinner",
                                    "Snacks Served All Day",
                                    "Weekly Theme Dinners",
                                    "Unlimited Beer, Wine, and Spirits",
                                    "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                                    "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                                    "Supervised Activities for Children Ages 4-12",
                                    "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                                    "Free yoga lessons are offered daily at 9am on the beach.",
                                ],
                            },
                            resort: {
                                gallery: [
                                    { url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/01-Resort/01.webp') },
                                    { url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/01-Resort/03.webp') },
                                    { url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/01-Resort/04.webp') },
                                    { url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/01-Resort/05.webp') },
                                    { url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/01-Resort/06.webp') }
                                ],
                                description:
                                    "<p>Si lo que buscas son unas vacaciones de lujo con relajación absoluta, Villa del Palmar en las Islas de Loreto es para ti.Con sus tranquilas playas, majestuosas vistas, rica historia y asombroso hábitat natural, sentirás como si hubieras entrado al paraíso.</p><p>Como el mejor hotel todo incluido de Loreto, Villa del Palmar en las Islas de Loreto ofrece todas las comodidades modernas que puedes desear y un servicio verdaderamente excepcional, sin dejar de lado el pintoresco ambiente del México tradicional.Este lujoso resort de playa en Loreto es la escapada perfecta para familias, amigos, parejas, recién casados, amantes del golf y mucho más.</p>",
                            },
                            rooms:
                            {
                                gallery: [
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/02-Suite/01.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/02-Suite/03.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/02-Suite/04.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/02-Suite/05.webp'),
                                        text: "",
                                    },
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/02-Suite/02.webp'),
                                        text: "",
                                    },
                                ],
                                name: "Deluxe Suite",
                                priceUSD: 699,
                                additionalNightPrice: '',
                                description:
                                    "<p>Amplia y reconfortante, esta habitación hospeda hasta 4 personas, combinando madera, mármol y terracota para crear una atmósfera placentera y agradable.En estas suites puedes disfrutar de una espectacular vista al Mar de Cortés desde tu balcón.Todas las habitaciones tienen una cama king size o bien, dos camas matrimoniales, amplios baños,comodidades de lujo, televisión satelital, conexión a internet, caja fuerte y cafetera con servicio de café incluido.También tenemos disponibles habitaciones para personas con discapacidad.</p>",
                                galleryDescription: "Be our guest and see it for yourself.",
                                features: [
                                    {
                                        feature: "Aire acondicionado",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "· Wi-fi gratis",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Televisión satelital",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Detector de humo",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Plancha y tabla para planchar",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Servicio de despertador",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Regadera",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Balcón",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Secadora para cabello",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Cuna",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Caja fuerte",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Teléfono",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Amenidades de baño",
                                        extraCharge: false
                                    },
                                    {
                                        feature: "Servicio a la habitación",
                                        extraCharge: true
                                    },
                                ]
                            },
                            amenities: {
                                gallery: [
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/03-Amenities/01.webp')
                                    },
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/03-Amenities/02.webp')
                                    },
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/03-Amenities/03.webp')
                                    },
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/03-Amenities/04.webp')
                                    },
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/03-Amenities/05.webp')
                                    },
                                    {
                                        url: require('@/assets/images/loreto/package/01-palmar-loreto-all-inclusive/03-Amenities/06.webp')
                                    },
                                ],
                                description: [
                                    {
                                        amenitie: "Campo de golf.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Jacuzzi.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Alberca común.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Alberca infantil.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "2 restaurantes.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Salón de eventos.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Servicios para bodas.",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "Servicios de Spa.",
                                        extraCharge: true
                                    },
                                    {
                                        amenitie: "Actividades acuáticas no motorizadas.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Bar.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Cancha de tennis.",
                                        extraCharge: false
                                    },
                                    {
                                        amenitie: "Gimnasio.",
                                        extraCharge: false
                                    },
                                ]
                            },
                            location: {
                                url: 'https://goo.gl/maps/TJisFvTLQDmtpcz86',
                                map: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14378.249213135376!2d-111.2335281!3d25.7189121!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x328727e664043480!2sVilla%20del%20Palmar%20at%20The%20Islands%20of%20Loreto!5e0!3m2!1ses-419!2smx!4v1661982816137!5m2!1ses-419!2smx'
                            },
                        },
                        // {
                        //     id: 6,
                        //     name: "Loreto Bay All Inclusive",
                        //     slug: "loreto-bay-all-inclusive",
                        //     image: { url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/01-Resort/03.webp') },
                        //     includeInHome: false,
                        //     soldOut: false,
                        //     nights: 4,
                        //     adults: 2,
                        //     children: 2,
                        //     childrenAgeRange: '',
                        //     plan: "All Inclusive",
                        //     retailPriceUSD: 1261,
                        //     priceUSD: 699,
                        //     additionalNightPrice: '',
                        //     status: '',
                        //     vip: {
                        //         isVip: true,
                        //         vipDiscount: 100
                        //     },
                        //     offer: {
                        //         rating: 5,
                        //         includes: [
                        //             "This amazing package includes 5 days and 4 nights for 2 adults.",
                        //             "All Inclusive plan.",
                        //         ],
                        //         description:
                        //             "<p>In the heart of Loreto Bay on the Sea of Cortez enjoy the crystal-clear turquoise waters and bask in the golden sun. The Beautiful Mountain range of the Sierra La Giganta forms an amazing backdrop to this amazing picturesque view. Let yourself go into a Zen of relaxation in this magical atmosphere. Explore the charming and enchanted streets of Loreto and take yourself back in time to the simpler less complicated days of life. This is the definitely the vacation that will take you away from it all.</p>",
                        //         allInclusive: [
                        //             "All Meals Daily, including Breakfast, Lunch, and Dinner",
                        //             "Snacks Served All Day",
                        //             "Weekly Theme Dinners",
                        //             "Unlimited Beer, Wine, and Spirits",
                        //             "Mini-Bar with Soft Drinks, Bottled Water (refilled on a daily basis)",
                        //             "Deluxe Accommodations with 1 King Size or 2 Double Beds, individually controlled Air Conditioning, Satellite TV, Safety Deposit Boxes, Hair Dryers and More",
                        //             "Supervised Activities for Children Ages 4-12",
                        //             "Scheduled Daily Activities: aqua aerobics, beach volleyball, ping pong, beach soccer, bingo, kayak tours, water polo, dance classes, cooking classes",
                        //             "Free yoga lessons are offered daily at 9am on the beach.",
                        //         ],
                        //     },
                        //     resort: {
                        //         gallery: [
                        //             { url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/01-Resort/02.webp') },
                        //             { url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/01-Resort/03.webp') },
                        //             { url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/01-Resort/04.webp') },
                        //             { url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/01-Resort/05.webp') },
                        //             { url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/01-Resort/06.webp') }
                        //         ],
                        //         description:
                        //             "<p>Loreto Bay Golf Resort & Spa is nestled on the shores of the Mar de Cortes, in the exclusive Nopolo Bay. The Sierra La Giganta Mountains and the vast desert join together to create a magical setting and a heavenly ambiance.</p><p>The beauty of this paradise emerges from each grain of sand, each wave and also from the majestic rock formations that create magnificent scenic views. In this exclusive enclave, you will enjoy the most attentive and sophisticated service.</p>",
                        //     },
                        //     rooms:
                        //     {
                        //         gallery: [
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/02-Suite/01.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/02-Suite/02.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/02-Suite/03.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/02-Suite/04.webp'),
                        //                 text: "",
                        //             },
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/02-Suite/05.webp'),
                        //                 text: "",
                        //             },
                        //         ],
                        //         name: "Junior Suite Standard",
                        //         priceUSD: 699,
                        //         additionalNightPrice: '',
                        //         description:
                        //             "<p>Spacious and comfortable Deluxe rooms for up to 4 people, combining fine woods, marble and terracotta to create a pleasant and relaxing atmosphere. In them you can enjoy a spectacular view of the Sea of Cortez from the balcony. All rooms have king size beds or two double beds, spacious bathrooms, luxury amenities, satellite TV, free Wi-Fi, safe deposit box and coffee maker with complimentary coffee service. Rooms for people with disabilities are also available.</p>",
                        //         galleryDescription: "Be our guest and see it for yourself.",
                        //         features: [
                        //             {
                        //                 feature: "Free Wi-Fi",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Smoke detector",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Iron and ironing board",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Satellite TV",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Air conditioning",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Wake-up service",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Shower",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Balcony",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Hair dryer",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Crib",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Safety box",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Telephone",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Bathroom amenities",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 feature: "Room service",
                        //                 extraCharge: false
                        //             },
                        //         ]
                        //     },
                        //     amenities: {
                        //         gallery: [
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/03-Amenities/01.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/03-Amenities/02.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/03-Amenities/03.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/03-Amenities/04.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/03-Amenities/05.webp')
                        //             },
                        //             {
                        //                 url: require('@/assets/images/loreto/package/02-loreto-bay-all-inclusive/03-Amenities/06.webp')
                        //             },
                        //         ],
                        //         description: [
                        //             {
                        //                 amenitie: "Golf course",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Jacuzzi",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "General swimming pool",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Children's pool",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "2 restaurants",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Events room",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Wedding services",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Spa services",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Non-motorized activities",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Sport Bars",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Tennis courts",
                        //                 extraCharge: false
                        //             },
                        //             {
                        //                 amenitie: "Fitness center",
                        //                 extraCharge: false
                        //             },
                        //         ]
                        //     },
                        //     location: {
                        //         url: 'https://goo.gl/maps/56k4Sfybf4qyTfww9',
                        //         map: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14353.776349382464!2d-111.3481078!3d25.9206394!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4387a1eec45d00e4!2sHotel%20Loreto%20Bay%20Golf%20Resort%20%26%20Spa!5e0!3m2!1ses!2smx!4v1674607354408!5m2!1ses!2smx'
                        //     },
                        // },
                    ],
                    physical: {
                        lat: 26.0097913,
                        lon: -111.3452523
                    }
                },
            ],
            packageSelected: JSON.parse(localStorage.getItem('package-selected')),
        }
    },
    getters: {
        getPhone() {
            let phone = '877 692 3883';
            if (window.location.pathname.includes('/es')) {
                phone = '800 461 0603 '
            }
            if (this.team) {
                const terminalSelected = this.phones.find(x => x.terminal == this.team);

                if (terminalSelected) phone = terminalSelected.phone;
            }
            if (this.sourcePhone) {
                const terminalSelected = this.phones.find(x => x.terminal == this.sourcePhone);

                if (terminalSelected) phone = terminalSelected.phone;
            }
            return phone;
        },
        getSource() {
            return localStorage.getItem('utm_source');
        },
        getChannel() {
            return this.channel;
        },
        getCampaign() {
            return this.leadID;
        },
        isVip() {
            return this.leadID != null
        },
        getJivochat() {
            var jivochatEn = document.createElement('script');
            jivochatEn.type = 'text/javascript';
            jivochatEn.src = '//code.jivosite.com/widget/g4zpIPbolV';

            var jivochatEs = document.createElement('script');
            jivochatEs.type = 'text/javascript';
            jivochatEs.src = '//code.jivosite.com/widget/fnYxFfMQb5';

            if (window.location.pathname.includes('/es')) {
                document.head.appendChild(jivochatEs);
            }
        },
        getPackages() {
            return ({ destinationId, destinationSlug, take = null, skip = 0 }) => {
                let filters = [];

                if (destinationId) filters.push(x => x.id == destinationId);
                if (destinationSlug) filters.push(x => x.slug == destinationSlug);

                let packages = this.destinations.reduce((acc, current) => {

                    if (filters.every(x => x(current))) {
                        acc.push(...current.packages.map(x => getPackageInfo(x, current)))
                    }

                    return acc;
                }, []);

                if (take && skip != null) packages = packages.slice(skip, take);

                return packages;
            }
        },
        getIsCampaign() {
            if (this.campaign.fromDate < this.now && this.campaign.toDate > this.now) {
                return true;
            } 
            
        }
        // getVipPackages(destinationId) {
        //   this.destinations.reduce((acc, current) => {
        //     acc.push(...current.vip.isVip)
        //   }, [])
        // }
    },
    actions: {
        setTeam(payload) {
            if (payload) {
                this.team = payload;
                localStorage.setItem('team', payload);
            }
        },
        setSource(source) {
            if (source) {
                switch (source) {
                    case 'google':
                        this.source = 49
                        break;
                    case 'facebook':
                        this.source = 50
                        break;
                    case 'contents':
                        this.source = 95
                        break;
                    case 'voxtelesys':
                        this.source = 96
                        break;
                    case 'mailjet':
                        this.source = 97
                        break;
                    default:
                        this.source = 49
                        break;
                }
                localStorage.setItem('utm_source', this.source);
            }
        },
        setSourcePhone(source) {
            if (source) {
                this.sourcePhone = source;
                localStorage.setItem('source_phone', this.source);
            }
        },
        setChannel(channel) {
            if (channel) {
                switch (channel) {
                    case 'google-content-ads':
                        this.channel = 212;
                        break;
                    case 'sms':
                        this.channel = 213;
                        break;
                    case 'email':
                        this.channel = 214;
                        break;
                    case 'ads-google':
                        this.channel = 2;
                        break;
                        case 'landingpage':
                            this.channel = 48;
                            break;
                    default:
                        break;
                }
                localStorage.setItem('utm_medium', this.channel);
            }
        },
        setCampaign(camp) {
            if (camp) {
                localStorage.setItem('utm_campaign', camp);
            }
        },
        setLeadID(payload) {
            if (payload) {
                this.leadID = payload;
                localStorage.setItem('leadID', payload);
            }
        },
        setPackage(payload) {
            this.packageSelected = payload;
            localStorage.setItem('package-selected', JSON.stringify(payload))
        },
    },
})
