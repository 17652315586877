<template>
  <!-- Button trigger modal -->
  <!--<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalBanner">
      Launch demo modal
  </button>-->

  <!-- Modal -->

  <!-- GAME INTERFACE-->
  <div class="modal overflow-hidden" id="modalBannerLottery" tabindex="-1" aria-labelledby="modalBannerLottery"
    aria-hidden="true">
    <transition name="fadeOut">
      <div class="modal-dialog modal-dialog-centered" v-show="isSelected == 1">
        <!-- INITIAL GAME INTERFACE-->
        <div class="modal-content modal-lottery--background__color lottery-modal col-12"
          :class="{ 'lottery-modal--hide': isSelected }">
          <div class="text-end">
            <button type="button" class="btn-close p-2 position-absolute end-0" 
              aria-label="Close" id="closeLotteryModal" @click="closeModal()"><img src="../assets/icons/close-white.svg"
                alt=""></button>
          </div>
          <div class="modal-body p-0 modal-lottery--background__color  text-center text-white" @click="setSelected()">
            <div class="modal-lottery--background__image">
              <div class="d-flex justify-content-center pt-4 pb-3">
                <div class="bg-warning col-7 rounded-3 title-bg pt-1">
                  <h2 class="fw-bolder p-0">Special delivery!</h2>
                </div>
              </div>
              <div :class="{ 'call-to-action--fade-out': isActive }">
                <h4 class="fw-bold m-0">Open your package</h4>
                <h4 class="fw-bold m-0">to discover</h4>
                <h3 class="fw-bold m-0">YOUR VACATION BONUS</h3>
              </div>
              <div class="call-to-action-box">
                <img src="../assets/images/campaigns/black-friday-2024/call-to-action.png" alt=""
                  class="img-box  pb-4 pt-4 shake" v-if="isActive == false">
              </div>
              <div class="d-block justify-content-center call-to-action-box" v-if="isActive">
                <img src="../assets/images/campaigns/black-friday-2024/call-to-action-open-01.png" alt=""
                  class="w-50 pb-4 pt-4">
                <div  :class="{ 'show-to-top': isActive }" class="d-block">
                  <img :src="prize.imgUrl"  alt="" class="img-prize-box"
                   >
                </div>
                <img src="../assets/images/campaigns/black-friday-2024/call-to-action-open-02.png" alt=""
                  class="w-50 pb-4 pt-4 call-to-action-cover">
              </div>
              <div class="justify-content-center pb-3">
                <div class="btn btn-play rounded-pill px-4 py-0">UNBOX NOW</div>
              </div>
              <div class="d-flex">
                <div class="col-9 text-start montserrat-lbl-01 lbl-03 p-4">
                  Does not apply with other promotions. <br>
                  Valid with the purchase a <br>
                  Vacation Package to Mexico. <br>
                  •only with the purchase of a $599 package.
                </div>
                <div class="col-3 text-end align-self-end">
                  <img src="../assets/images/campaigns/black-friday-2024/logo.png" alt="" class="w-75 ">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- PRIZE INTERFACE-->
    <transition name="fadeIn">
      <div class="modal-dialog modal-dialog-centered" v-if="isSelected == 2">
        <div class="modal-content modal-lottery--background__color prize-modal col-12">
          <div class="modal-header modal-header--prize text-center border-0">
            <div class="col">
              <h6 class="modal-title modal-title--prize text-white">Delivered!</h6>
            </div>
          </div>
          <div class="text-end position-absolute end-0 me-1">
            <button type="button" class="btn-close p-2 position-absolute end-0" data-bs-dismiss="modal"
              aria-label="Close" id="closeBtn"><img src="../assets/icons/close-white.svg" alt=""></button>
          </div>
          <div class="modal-body p-0 text-center text-white">
            <h4 class="fw-bold text-white pt-4">YOU'VE WON</h4>
            <div class="shake"><img :src="prize.imgUrl" alt="" class="´prize-img py-3 py-lg-3"
                :class="{ 'fade-zoom-in prize-img ': isSelected }"></div>
            <p class="montserrat-lbl-01 lbl-terms">*Valid with the purchase of a vacation package to Mexico.
            </p>
            <the-lottery-form :prizeCode="prize.code" :prizeName="prize.name" />
            <h4 class="lbl-redem pt-3 pt-lg-0">TO REDEEM YOUR PRIZE!</h4>
          </div>
        </div>
      </div>
    </Transition>

    <!-- FINAL INTERFACE-->
    <transition name="fadeIn">
      <div class="modal-dialog modal-dialog-centered" v-if="isSelected == 3">
        <div class="modal-content modal-lottery--background__color prize-modal col-12">
          <div class="modal-header modal-header--prize text-center">
            <div class="col">
              <h4 class="modal-title modal-title--prize">THANK YOU!</h4>
            </div>
            <button type="button" class="btn-close p-2" data-bs-dismiss="modal" aria-label="Close"><img
                src="../assets/icons/close-white.svg" alt=""></button>
          </div>
          <div class="modal-body p-0 text-center text-white">

            <h4 class="lbl-redem p-5 pb-2">FOR PLAYING AND <br> REGISTERING<br> YOUR INFORMATION </h4>
            <h4 class="montserrat-lbl-01 pb-5 lbl-05">A VACATION EXPERT WILL <br> CONTACT YOU SHORTLY</h4>
            <!-- <img src="../assets/images/lottery/back-to-school/footer-img.png" alt="" class="img-fluid"> -->
          </div>
        </div>
      </div>
    </Transition>

  </div>
  <div class="position-fixed bottom-0 end-0 w-100 validation-toast">
    <div class="d-lg-flex justify-content-end">
      <div id="liveToast" class="toast text-bg-danger w-100" role="alert" aria-live="assertive" aria-atomic="true"
        data-bs-delay="3000">
        <div class="toast-header">
          <strong class="me-auto pe-3">Please provide this information</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheLotteryForm from './TheLotteryForm.vue';
import Confetti from 'canvas-confetti'
import { useWebsiteStore } from '@/store/website';

export default {
  data() {
    return {
      bannerUrl: "",
      now: new Date(),
      fromDate: new Date(2024, 8, 6, 0, 0, 0),
      toDate: new Date(2024, 11, 18, 23, 59, 59),
      fromSecondDate: new Date(2024, 2, 11, 0, 0, 0),
      toSecondDate: new Date(2024, 2, 31, 23, 59, 59),
      now: new Date(),
      isSelected: 1,
      isActive: false,
      isOn: false,
      prize:
      {
        name: '',
        code: '',
        imgUrl: '',
      }
    }
  },
  components: {
    TheLotteryForm
  },
  methods: {
    showModal() {
      let bannerModal = document.querySelector('#modalBannerLottery', { backdrop: false });
      console.log(bannerModal)
      //eslint-disable-next-line
      const bannerModalInstance = new bootstrap.Modal(bannerModal);
      bannerModalInstance.show();
    },
    closeModal() {
      var myModalEl = document.querySelector('#modalBannerLottery');
      myModalEl.classList.remove('backdrop-modal');
      var myModal = bootstrap.Modal.getOrCreateInstance(myModalEl);
      myModal.hide();
    },
    setSelected() {
      this.isActive = true
      this.getConfetti
      setTimeout(() => {
        this.isSelected = 2
        this.getConfetti
      }, 2000);
    },
    setRandomNumber(max) {
      return Math.floor(Math.random() * max);
    },
    turnOn() {
      this.isOn = true
      setTimeout(() => {
        this.setSelected()
      }, 500);
    },
    setPrize() {
      switch (this.setRandomNumber(4)) {
        case 0:
          this.prize.name = 'FREE CRUISE',
            this.prize.code = '#VALCRUISE',
            this.prize.imgUrl = require('@/assets/images/campaigns/black-friday-2024/prizes/prize-01.png')
          break;
        case 1:
          this.prize.name = '$100 USD DISCOUNT',
            this.prize.code = '#B100DIS',
            this.prize.imgUrl = require('@/assets/images/campaigns/black-friday-2024/prizes/prize-02.png')
          break;
        case 2:
          this.prize.name = 'FREE VACATION LAS VEGAS',
            this.prize.code = '#VALVEGAS',
            this.prize.imgUrl = require('@/assets/images/campaigns/black-friday-2024/prizes/prize-03.png')
          break;
        case 3:
          this.prize.name = '$75 usd DISCOUNT',
            this.prize.code = '#VAL75DIS',
            this.prize.imgUrl = require('@/assets/images/campaigns/black-friday-2024/prizes/prize-04.png')
          break;
        case 4:
          this.prize.name = '$50 usd DISCOUNT',
            this.prize.code = '#VAL50DIS',
            this.prize.imgUrl = require('@/assets/images/campaigns/black-friday-2024/prizes/prize-05.png')
          break;
        // case 2:
        //     this.prize.name = '2X1 GOLF ROUND',
        //         this.prize.code = '#B2X1GOLF',
        //         this.prize.imgUrl = 'images/lottery/back-to-school/prizes/prize-03.png'
        //     break;
        default:
          break;
      }
    }
  },
  computed: {
    getBanner() {
      if (this.$i18n.locale == "eng") {
        this.bannerUrl = 'images/campaigns/banners/banner-fourthjuly.png'
      } else {
        this.bannerUrl = 'images/campaigns/banners/banner-fourthjuly.png'
      }
      return this.bannerUrl
    },
    getConfetti() {
      Confetti({
        particleCount: 500,
        spread: 70,
        origin: { y: 0.6 },
        zIndex: 2000,
        shapes: ['square'],
        colors: ['#e57403', '#c4085a', '#008d36', '#36a9e1'],
        startVelocity: 20,
      })
    }
  },
  setup() {
    const websiteStore = useWebsiteStore();
    const getImageUrl = (name) => {
      return new URL(`../assets/${name}`, import.meta.url).href
    }
    return {
      getImageUrl, websiteStore
    }
  },
  mounted() {
    // if (this.websiteStore.getIsCampaign) {
    //   this.setPrize();
    //   this.showModal();
    // }
  }

}

</script>

<style scoped>
.modal-lottery--background__color {
  background-color: #1d1d1b;
}

.modal-lottery--background__image {
  background-image: url(../assets/images/campaigns/black-friday-2024/bg-image.png);
  background-size: cover;
  background-position: center;
  background-position-y: 111px;
  background-repeat: no-repeat;
}

.btn-close {
  opacity: 1 !important;
  z-index: 3 !important;
  background: none !important;
  ;
}

.modal-backdrop {
  z-index: -1;
}

.montserrat-lbl-01 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  position: relative;
  z-index: 3 !important;
}

.montserrat-lbl-02 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  position: relative;
  z-index: 3 !important;
}

.title-img {
  position: relative;
  z-index: 5000 !important;
}

.lbl-03 {
  font-size: 10px;
}

.lbl-05 {
  color: #F1A619;
  ;
}

#modalBannerLottery {
  background-color: rgba(0, 0, 0, 0.5);
}

.logo--w {
  z-index: 3 !important;
  width: 15%;
}

.validation-toast {
  z-index: 2000 !important;
}

.modal-header--prize {
  background: #d5075a;
}

.modal-title--prize {
 
  font-weight: 800;
  font-size: 2rem !important;
  color: #173e5d;
}

.lbl-terms {
  font-size: 12px;
}

.lbl-redem {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.lamp-img {
  z-index: 0;
  left: 39%;
}

.bg-fade {
  background-color: rgb(0, 0, 0, .8);
  position: absolute;
  width: 100%;
  z-index: 1;
}

.btn-spin-02 {
  background-color: #fff !important;
  font-family: Bebas Neue;
  color: #d4ab13;
  border-color: #000 !important;
  font-size: 30px;
  box-shadow: 2px 11px 5px 6px rgba(153, 28, 81, 1);
  -webkit-box-shadow: 1px 10px 0px -1px rgba(144, 86, 15, 1);
  -moz-box-shadow: 2px 11px 5px 6px rgba(153, 28, 81, 1);
  position: relative;
  margin-top: 180px !important;
  z-index: 3 !important;
}

.prize-img {
  width: 40% !important;
  z-index: 0;
}

.prize-lbl {
  text-shadow: rgb(243, 146, 0) 7px 0px 0px, rgb(243, 146, 0) 6.92869px 0.996602px 0px, rgb(243, 146, 0) 6.71622px 1.9729px 0px, rgb(243, 146, 0) 6.36692px 2.909px 0px, rgb(243, 146, 0) 5.8879px 3.78584px 0px, rgb(243, 146, 0) 5.28893px 4.58555px 0px, rgb(243, 146, 0) 4.5822px 5.29183px 0px, rgb(243, 146, 0) 3.78212px 5.8903px 0px, rgb(243, 146, 0) 2.90498px 6.36876px 0px, rgb(243, 146, 0) 1.96865px 6.71747px 0px, rgb(243, 146, 0) 0.992221px 6.92932px 0px, rgb(243, 146, 0) -0.00442571px 7px 0px, rgb(243, 146, 0) -1.00098px 6.92806px 0px, rgb(243, 146, 0) -1.97715px 6.71498px 0px, rgb(243, 146, 0) -2.91303px 6.36508px 0px, rgb(243, 146, 0) -3.78956px 5.88551px 0px, rgb(243, 146, 0) -4.58889px 5.28603px 0px, rgb(243, 146, 0) -5.29472px 4.57885px 0px, rgb(243, 146, 0) -5.89269px 3.77839px 0px, rgb(243, 146, 0) -6.3706px 2.90095px 0px, rgb(243, 146, 0) -6.71871px 1.96441px 0px, rgb(243, 146, 0) -6.92995px 0.98784px 0px, rgb(243, 146, 0) -6.99999px -0.00885142px 0px, rgb(243, 146, 0) -6.92743px -1.00536px 0px, rgb(243, 146, 0) -6.71372px -1.98139px 0px, rgb(243, 146, 0) -6.36324px -2.91705px 0px, rgb(243, 146, 0) -5.88311px -3.79328px 0px, rgb(243, 146, 0) -5.28313px -4.59223px 0px, rgb(243, 146, 0) -4.57551px -5.29762px 0px, rgb(243, 146, 0) -3.77466px -5.89507px 0px, rgb(243, 146, 0) -2.89692px -6.37243px 0px, rgb(243, 146, 0) -1.96016px -6.71995px 0px, rgb(243, 146, 0) -0.983458px -6.93057px 0px, rgb(243, 146, 0) 0.0132771px -6.99999px 0px, rgb(243, 146, 0) 1.00974px -6.92679px 0px, rgb(243, 146, 0) 1.98564px -6.71247px 0px, rgb(243, 146, 0) 2.92107px -6.36139px 0px, rgb(243, 146, 0) 3.797px -5.88071px 0px, rgb(243, 146, 0) 4.59557px -5.28022px 0px, rgb(243, 146, 0) 5.30051px -4.57215px 0px, rgb(243, 146, 0) 5.89746px -3.77094px 0px, rgb(243, 146, 0) 6.37426px -2.89289px 0px, rgb(243, 146, 0) 6.72119px -1.95591px 0px, rgb(243, 146, 0) 6.93119px -0.979076px 0px;
  font-weight: bold;
  margin-top: -24px;
  z-index: 5 !important;
}

#liveToast .toast-header {
  background-color: var(--bs-form-invalid-border-color);
  background-clip: no-clip;
  border: 15px !important;
  border-radius: 0px !important;
  color: white;
}

/*-- new --*/
.call-to-action-cover {
    position: relative;
    margin-top: -115px;
    margin-left: -15px;
}

.call-to-action-box {
  justify-items: center;
}

.img-prize-box {
  width: 35%;
  position: relative;
  margin-top: -180px;
  bottom: -63px;
  filter: drop-shadow(0 0 0.75rem crimson);
}

.img-box {
  width: 40%;
}

.btn-play {
  background-color: #fff;
  color: #3c1349;
  font-weight: 500;
  font-size: 1.8rem;
  font-family: Bebas Neue;
  box-shadow: 0px 8px 0px -3px rgb(109, 65, 132);
}

.title-bg {
  background: rgb(179, 7, 83);
  background: linear-gradient(180deg, rgba(179, 7, 83, 1) 0%, rgba(214, 5, 89, 1) 100%);
}

@media (min-width: 992px) {
  .btn-spin-02 {
    margin-top: 210px !important;
  }

  .modal-title--prize {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 2.3rem;
    color: #173e5d;
  }
}

@media (min-width: 576px) { 
  .call-to-action-cover {
  position: relative;
  margin-top: -128px;
  margin-left: -15px;
}
}
/* --------------- ANIMATION ------------*/

.call-to-action--fade-out {
  animation-name: fadeIn !important;
  animation-duration: 1s !important;
  animation-iteration-count: unset !important;
  animation-fill-mode: forwards;
}

.show-to-top {
  animation-name: goUp !important;
  animation-duration: 2s !important;
  animation-fill-mode: forwards;
}

.swing--initial-state {
  animation: swing ease-in-out 1s infinite alternate;
  transform-origin: center -20px;
  float: center;

}

.swing--selected-state {
  animation: swing ease-in-out .1s infinite alternate;
  transform-origin: center -20px;
  float: center;
}


.swing:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  top: -10px;
  left: 50%;
  z-index: 0;
  border-bottom: none;
  border-right: none;
  transform: rotate(45deg);
}

/* nail */
.swing:before {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  top: -14px;
  left: 54%;
  z-index: 5;
  border-radius: 50% 50%;
  background: #000;
}

@keyframes swing {
  0% {
    transform: rotate(3deg);
  }

  100% {
    transform: rotate(-3deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


@keyframes goUp {
  100% {
    transform: translateY(-550%);
}

}

/*Display transition*/

/* .lottery-modal {
  transition: visibility 1.5s, opacity 1.5s;
  transition-delay: 1s;
}

.lottery-modal--hide {
  visibility: hidden;
  opacity: 0;
} */

/* .prize-modal{
  visibility: hidden;
  opacity: 0;
  transition: visibility 5s, opacity 5s;
  transition-delay: 1s;
}

.prize-modal--show {
  visibility: visible;
  opacity: 1;
} */

/*---------------------- */


/*----Vue Transition----------- */
.fadeOut-enter-active,
.fadeOut-leave-active {
  transition: opacity .5s ease;
}

.fadeOut-enter-from,
.fadeOut-leave-to {
  opacity: 0;
}

.fadeIn-enter-active,
.fadeIn-leave-active {
  transition: opacity 1.5s ease-out;
}

.fadeIn-enter-from,
.fadeIn-leave-to {
  opacity: 0;
}

/*--------------- Prize Animation ------------*/
.prize-down {
  animation-duration: 2s;
  animation-name: slideindown;
}

.prize-up {
  animation-duration: 2s;
  animation-name: slideinup;
}

.prize-up-out {
  animation-duration: 3s;
  animation-name: slideoutup;
  animation-fill-mode: forwards
}

.turn-on-light {
  animation-duration: .5s;
  animation-name: turnonlight;
  animation-fill-mode: forwards
}

.shake {
  animation-duration: 2s;
  animation-name: shakeit;
  animation-iteration-count: infinite;
}

.fade-zoom-in {
  animation-duration: 2s;
  animation-name: fadezoomin;
}

.swing--selected-state {
  animation: swing ease-in-out .1s infinite alternate;
  transform-origin: center -20px;
  float: center;

}


@keyframes slideindown {
  from {
    translate: 0 -150vw;
    scale: 200% 1;
  }

  to {
    translate: 0 0;
    scale: 100% 1;
  }
}

@keyframes slideoutup {
  from {
    translate: 0 0;
  }

  to {
    translate: 0 -750px;
  }
}

@keyframes slideinup {
  from {
    translate: 0 150vw;
    scale: 200% 1;
  }

  to {
    translate: 0 0;
    scale: 100% 1;
  }
}

@keyframes turnonlight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }

}

@keyframes shakeit {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(-5deg);
  }

  75% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes fadezoomin {
  from {
    opacity: 0;
    transform: scale(0.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
